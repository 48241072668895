import * as THREE from "three";
import * as ifvisible from "ifvisible";
import { ControlPanel } from "./control_panel";
import { ParticleEmitter, ParticleInitializer, ParticleOperator, GetFunctionFromID, ParticlePropertiesTypes, ParticleForces, } from "./particles_operators";
import { Color, downloadJson, isNumeric, loadFile, preLoadShader, shaderLoadedSuccessfully, Vector, } from "./util";
import { MathUtils } from "three";
import { ParticleSystem } from "./particles";
import { ParticleRenderer } from "./particle_renderer";
import { ControlPoints } from "./control_points";
import { ParticleFunctions } from "./particle_function_util";
export class ParticleScene {
    constructor(options) {
        this.oldTick = 0;
        this.lastFps = [];
        this.cameraVec = new THREE.Vector3();
        this.cameraLeft = new THREE.Vector3(1, 0, 0);
        this.cameraUp = new THREE.Vector3(0, 1, 0);
        this.upVector = new THREE.Vector3(0, 0, 1);
        this.curAngle = 0;
        this.lines = [];
        this.isEditor = options?.isEditor ?? false;
        const body = document.body;
        if (options && options.rootElement) {
            this.rootElement = options.rootElement;
        }
        else {
            this.rootElement = document.createElement("div");
            this.rootElement.setAttribute("id", "ParticleScene");
            body.appendChild(this.rootElement);
            const warnElement = document.createElement("div");
            warnElement.setAttribute("id", "ParticleSceneWarning");
            warnElement.innerHTML = "Not available on mobile devices";
            body.appendChild(warnElement);
        }
        if (options && options.isEditor) {
            this.controlPanel = new ControlPanel(this.rootElement, this);
            this.initStatusElement();
        }
        let height = this.rootElement.clientHeight;
        let width = this.rootElement.clientWidth;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        // this.camera.setFocalLength(10);
        this.renderer = new THREE.WebGLRenderer({
            alpha: true,
            // logarithmicDepthBuffer: true,
        });
        // const viewSize = 20;
        // const aspectRatio =
        // 	this.renderer.domElement.width / this.renderer.domElement.height;
        // this.camera = new THREE.OrthographicCamera(
        // 	(-aspectRatio * viewSize) / 2,
        // 	(aspectRatio * viewSize) / 2,
        // 	viewSize / 2,
        // 	-viewSize / 2,
        // 	-1000,
        // 	1000
        // );
        if (options && options.isEditor) {
            this.controlPoints = new ControlPoints(this.scene, this.camera, this.renderer, this.rootElement);
        }
        else {
            this.controlPoints = new ControlPoints(this.scene, this.camera, this.renderer);
        }
        const lightPos = new Vector(5, 5, 10);
        const light = new THREE.PointLight(0xffffff, 0.6, 50);
        light.position.set(lightPos.x, lightPos.y, lightPos.z);
        this.scene.add(light);
        const ambientLight = new THREE.AmbientLight(0x404040, 1); // soft white light
        this.scene.add(ambientLight);
        this.renderer.setSize(width, height);
        this.renderer.setClearColor(0x000000, 0);
        this.rootElement.appendChild(this.renderer.domElement);
        window.addEventListener("resize", () => {
            this.onWindowResize();
        }, false);
        if (options && options.isEditor) {
            this.drawGrid();
            this.createControls();
        }
        requestAnimationFrame((tick) => {
            this.renderLoop(tick);
        });
        if (!this.isEditor) {
            this.setDefaultCameraPosition();
            this.startSystem();
        }
    }
    getHTMLElement() {
        return this.rootElement;
    }
    getRootSystem() {
        return this.currentSystem;
    }
    getRenderScene() {
        return this.scene;
    }
    getControlPanel() {
        return this.controlPanel;
    }
    getCamera() {
        return this.camera;
    }
    renderLoop(curTick) {
        const delta = (curTick - this.oldTick) / 1000;
        this.oldTick = curTick;
        let fps = 1 / delta;
        if (this.lastFps.length > 20) {
            this.lastFps.shift();
        }
        this.lastFps.push(fps);
        let finalFps = 0;
        this.lastFps.forEach((f) => {
            finalFps += f;
        });
        finalFps = Math.floor(finalFps / this.lastFps.length);
        this.setParticleFPS(finalFps);
        requestAnimationFrame((tick) => {
            this.renderLoop(tick);
        });
        if (this.currentSystem) {
            this.currentSystem.tick(delta);
        }
        this.controlPoints.renderMover();
        // this.controls.update();
        // this.camera.rotation.z += 0.01;
        this.renderer.render(this.scene, this.camera);
    }
    createControls() {
        this.setDefaultCameraPosition();
        let keys = {
            w: false,
            a: false,
            s: false,
            d: false,
        };
        // let rotation = {
        // 	x: this.camera.rotation.x,
        // 	y: this.camera.rotation.y,
        // };
        let rotation = {
            x: 0,
            y: 0,
        };
        let out = false;
        document.addEventListener("keydown", (e) => {
            if (out)
                return;
            if (document.activeElement !== document.body)
                return;
            if (e.key === "w") {
                keys.w = true;
            }
            if (e.key === "a") {
                keys.a = true;
            }
            if (e.key === "s") {
                keys.s = true;
            }
            if (e.key === "d") {
                keys.d = true;
            }
            if (e.key === " ") {
                this.togglePlay();
            }
        });
        document.addEventListener("keyup", (e) => {
            if (e.key === "w") {
                keys.w = false;
            }
            if (e.key === "a") {
                keys.a = false;
            }
            if (e.key === "s") {
                keys.s = false;
            }
            if (e.key === "d") {
                keys.d = false;
            }
        });
        let zoomSpeed = 1;
        document.addEventListener("wheel", (e) => {
            if (e.target) {
                const target = e.target;
                if (target.tagName.toLowerCase() != "canvas") {
                    return;
                }
            }
            const zoom = e.deltaY / 10;
            this.camera.position.add(this.cameraVec.clone().multiplyScalar(-zoom * zoomSpeed));
        }, { passive: false });
        document.addEventListener("contextmenu", (e) => {
            e.preventDefault();
            return false;
        });
        let rotating = false;
        let mousePos = {
            x: 0,
            y: 0,
        };
        document.addEventListener("mousedown", (e) => {
            if (e.button === 2) {
                rotating = true;
                mousePos = {
                    x: e.clientX,
                    y: e.clientY,
                };
            }
        });
        document.addEventListener("mouseup", (e) => {
            if (e.button === 2) {
                rotating = false;
            }
        });
        document.addEventListener("mousemove", (e) => {
            if (rotating) {
                let deltaX = mousePos.x - e.clientX;
                let deltaY = mousePos.y - e.clientY;
                rotation.x += deltaX / 4;
                rotation.y += deltaY / 4;
                mousePos = {
                    x: e.clientX,
                    y: e.clientY,
                };
            }
        });
        let moveSpeed = 0.2;
        let upAngle = this.cameraVec.angleTo(this.upVector);
        upAngle = MathUtils.radToDeg(upAngle);
        this.curAngle = upAngle;
        setInterval(() => {
            if (!ifvisible.now()) {
                keys = {
                    w: false,
                    a: false,
                    s: false,
                    d: false,
                };
                return;
            }
            this.camera.getWorldDirection(this.cameraVec);
            let viewChanged = false;
            if (keys.w) {
                // this.viewSize -= moveSpeed;
                // viewChanged = true;
                this.camera.position.add(this.cameraVec.clone().multiplyScalar(moveSpeed));
            }
            if (keys.a) {
                this.camera.position.add(this.cameraLeft.clone().multiplyScalar(-moveSpeed));
            }
            if (keys.s) {
                // this.viewSize += moveSpeed;
                // viewChanged = true;
                this.camera.position.add(this.cameraVec.clone().multiplyScalar(-moveSpeed));
            }
            if (keys.d) {
                this.camera.position.add(this.cameraLeft.clone().multiplyScalar(moveSpeed));
            }
            // if (viewChanged) {
            // 	const aspectRatio =
            // 		this.renderer.domElement.width /
            // 		this.renderer.domElement.height;
            // 	this.camera.left = (-aspectRatio * this.viewSize) / 2;
            // 	this.camera.right = (aspectRatio * this.viewSize) / 2;
            // 	this.camera.top = this.viewSize / 2;
            // 	this.camera.bottom = -this.viewSize / 2;
            // 	this.camera.updateProjectionMatrix();
            // }
            let yRotation = rotation.y;
            if (this.curAngle + rotation.y < 70) {
                yRotation = -Math.max(this.curAngle - 70, 0);
                this.curAngle = 70;
            }
            else if (this.curAngle + rotation.y > 250) {
                yRotation = Math.max(250 - this.curAngle, 0);
                this.curAngle = 250;
            }
            else {
                this.curAngle += yRotation;
            }
            this.cameraVec.applyAxisAngle(this.cameraLeft, MathUtils.degToRad(yRotation));
            this.cameraUp.applyAxisAngle(this.cameraLeft, MathUtils.degToRad(yRotation));
            this.cameraVec.applyAxisAngle(this.upVector, MathUtils.degToRad(rotation.x));
            this.cameraLeft.applyAxisAngle(this.upVector, MathUtils.degToRad(rotation.x));
            this.cameraUp.applyAxisAngle(this.upVector, MathUtils.degToRad(rotation.x));
            let lookLoc = this.cameraVec.clone().add(this.camera.position);
            this.camera.up = this.cameraUp;
            this.camera.lookAt(lookLoc);
            // this.camera.rotateX(0.001);
            // this.camera.rotation.x += 0.001;
            // this.camera.getWorldDirection()
            rotation = {
                x: 0,
                y: 0,
            };
            this.camera.updateProjectionMatrix();
        }, 10);
        document.addEventListener("mouseleave", () => {
            keys = {
                w: false,
                a: false,
                s: false,
                d: false,
            };
            out = true;
            rotating = false;
        });
        document.addEventListener("mouseenter", () => {
            out = false;
        });
    }
    manualZoom(amount) {
        this.camera.position.add(this.cameraVec.clone().multiplyScalar(amount));
        this.camera.updateProjectionMatrix();
    }
    manualMove(direction) {
        this.camera.position.add(direction.exportThree());
    }
    setDefaultCameraPosition() {
        // this.viewSize = 20;
        // this.camera.position.set(-5, -5, 5);
        // // this.camera.position.z = 5;
        // // this.camera.position.y = -5;
        // // this.camera.position.x = -5;
        // this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        // this.camera.rotateZ(MathUtils.degToRad(-60));
        // this.cameraVec
        // 	.subVectors(new THREE.Vector3(0, 0, 0), this.camera.position)
        // 	.normalize();
        // this.cameraUp
        // 	.subVectors(new THREE.Vector3(0, 0, 10), this.camera.position)
        // 	.normalize();
        // this.cameraLeft
        // 	.subVectors(new THREE.Vector3(0, -10, 5), this.camera.position)
        // 	.normalize();
        // let upAngle = this.cameraVec.angleTo(this.upVector);
        // upAngle = MathUtils.radToDeg(upAngle);
        // this.curAngle = upAngle;
        this.camera.position.z = 10;
        this.camera.position.y = 10;
        this.camera.position.x = 10;
        this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        // not quite sure why?
        if (!this.isEditor) {
            this.camera.rotateZ(MathUtils.degToRad(120));
        }
        else {
            this.camera.rotateZ(MathUtils.degToRad(-60));
        }
        this.cameraVec.subVectors(new THREE.Vector3(0, 0, 0), this.camera.position).normalize();
        this.cameraUp.subVectors(new THREE.Vector3(0, 0, 20), this.camera.position).normalize();
        this.cameraLeft.subVectors(new THREE.Vector3(0, 20, 10), this.camera.position).normalize();
        let upAngle = this.cameraVec.angleTo(this.upVector);
        upAngle = MathUtils.radToDeg(upAngle);
        this.curAngle = upAngle;
    }
    initStatusElement() {
        this.statusElement = document.createElement("div");
        this.statusElement.setAttribute("id", "ParticleStatus");
        this.rootElement.appendChild(this.statusElement);
        const statusBox = document.createElement("div");
        statusBox.setAttribute("id", "ParticleStatusBox");
        this.statusElement.appendChild(statusBox);
        this.nameLabel = document.createElement("div");
        this.nameLabel.setAttribute("id", "ParticleStatusName");
        this.nameLabel.innerHTML = "Test";
        statusBox.appendChild(this.nameLabel);
        this.countLabel = document.createElement("div");
        this.countLabel.setAttribute("id", "ParticleStatusCount");
        this.countLabel.innerHTML = "0 / 100";
        statusBox.appendChild(this.countLabel);
        this.fpsLabel = document.createElement("div");
        this.fpsLabel.setAttribute("id", "ParticleStatusFPS");
        this.fpsLabel.innerHTML = "60 FPS";
        statusBox.appendChild(this.fpsLabel);
        // Function Buttons
        const playButton = document.createElement("div");
        playButton.classList.add("ParticleStatusButton");
        playButton.setAttribute("id", "ParticlePlayButton");
        playButton.setAttribute("title", "Pause Particles");
        this.statusElement.appendChild(playButton);
        this.playButton = playButton;
        playButton.addEventListener("click", (e) => {
            this.togglePlay();
        });
        const restartButton = document.createElement("div");
        restartButton.classList.add("ParticleStatusButton");
        restartButton.setAttribute("id", "ParticleRestartButton");
        restartButton.setAttribute("title", "Restart Effect");
        this.statusElement.appendChild(restartButton);
        restartButton.addEventListener("click", (e) => {
            if (this.currentSystem) {
                this.currentSystem.restart();
            }
        });
        const resetButton = document.createElement("div");
        resetButton.classList.add("ParticleStatusButton");
        resetButton.setAttribute("id", "CameraResetButton");
        resetButton.setAttribute("title", "Reset Camera Position");
        this.statusElement.appendChild(resetButton);
        resetButton.addEventListener("click", (e) => {
            this.setDefaultCameraPosition();
        });
        let gridButton = document.createElement("div");
        gridButton.classList.add("ParticleStatusButton");
        gridButton.setAttribute("id", "GridToggleButton");
        gridButton.setAttribute("title", "Toggle Grid Visibility");
        this.statusElement.appendChild(gridButton);
        let gridOn = true;
        gridButton.addEventListener("click", (e) => {
            if (gridOn) {
                this.hideGrid();
                gridOn = false;
            }
            else {
                this.showGrid();
                gridOn = true;
            }
        });
        const exportButton = document.createElement("div");
        exportButton.classList.add("ParticleStatusButton");
        exportButton.setAttribute("id", "ExportButton");
        exportButton.setAttribute("title", "Export particle to JSON");
        this.statusElement.appendChild(exportButton);
        exportButton.addEventListener("click", () => {
            const response = confirm("Do you want to download this particle as .json?");
            if (response) {
                this.exportToJson();
                downloadJson(this.exportToJson(), "particle");
            }
        });
        const importButton = document.createElement("div");
        // importButton.type = "file";
        importButton.classList.add("ParticleStatusButton");
        importButton.setAttribute("id", "ImportButton");
        importButton.setAttribute("title", "Import particle JSON");
        this.statusElement.appendChild(importButton);
        importButton.addEventListener("click", () => {
            importChooser.click();
        });
        const importChooser = document.createElement("input");
        importChooser.type = "file";
        importButton.appendChild(importChooser);
        importChooser.addEventListener("change", async () => {
            if (!importChooser.files || importChooser.files.length < 1)
                return;
            const reader = new FileReader();
            reader.onload = (event) => {
                if (!event.target)
                    return;
                const content = JSON.parse(event.target.result);
                this.loadJson(content);
            };
            reader.readAsText(importChooser.files[0]);
        });
    }
    exportToJson() {
        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlParams);
        const newParams = {};
        for (const [key, data] of Object.entries(params)) {
            const funcs = ParticleFunctions.getFuncFromUrlString(data);
            if (funcs.length > 0)
                newParams[key] = funcs;
        }
        return JSON.stringify(newParams, undefined, "\t");
    }
    togglePlay() {
        if (!this.playButton)
            return;
        if (this.playButton.classList.contains("paused")) {
            this.playButton.classList.remove("paused");
            this.playButton.setAttribute("title", "Pause Particles");
            if (this.currentSystem)
                this.currentSystem.continue();
        }
        else {
            this.playButton.classList.add("paused");
            this.playButton.setAttribute("title", "Resume Particles");
            if (this.currentSystem)
                this.currentSystem.pause();
        }
    }
    getParticleScene() {
        return this.rootElement;
    }
    onWindowResize() {
        let height = this.rootElement.clientHeight;
        let width = this.rootElement.clientWidth;
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);
    }
    setParticleCount(count, maxCount) {
        if (this.countLabel)
            this.countLabel.innerHTML = count + " / " + maxCount;
    }
    setParticleName(name) {
        if (this.nameLabel)
            this.nameLabel.innerHTML = name;
    }
    setParticleFPS(fps) {
        if (this.fpsLabel)
            this.fpsLabel.innerHTML = fps + " FPS";
    }
    async loadTestParticle() {
        return this.loadSceneFromUrl();
    }
    async ensureShadersLoaded() {
        if (!shaderLoadedSuccessfully())
            await preLoadShader();
    }
    async loadJson(file) {
        if (!this.currentSystem) {
            this.startSystem();
        }
        await this.ensureShadersLoaded();
        const loadInfo = (type, infoList) => {
            for (const info of infoList) {
                if (this.controlPanel) {
                    this.controlPanel.loadFunction(type, info.name, info.args ?? {}, info.disabled ?? false);
                }
                else {
                    const args = ParticleFunctions.parseRawInfo(type, info);
                    if (!args)
                        continue;
                    this.addParticleFunction(-1, info.name, args, info.disabled);
                }
            }
        };
        if (file.Initializer)
            loadInfo("Initializer" /* FunctionType.Initializer */, file.Initializer);
        if (file.Operator)
            loadInfo("Operator" /* FunctionType.Operator */, file.Operator);
        if (file.Emitter)
            loadInfo("Emitter" /* FunctionType.Emitter */, file.Emitter);
        if (file.Renderer)
            loadInfo("Renderer" /* FunctionType.Renderer */, file.Renderer);
        if (file.Force)
            loadInfo("Force" /* FunctionType.Force */, file.Force);
        if (this.controlPanel)
            this.controlPanel.forceOpenControl();
        setTimeout(() => {
            this.currentSystem.shouldStart = true;
            this.currentSystem.start();
            console.log(this.currentSystem);
        }, 1000);
    }
    async loadJsonPath(path) {
        const file = await loadFile(path);
        const info = JSON.parse(file);
        await this.loadJson(info);
    }
    removeParticleFunction(oldId) {
        if (!this.currentSystem)
            return;
        let oldFunc = GetFunctionFromID(oldId);
        if (oldFunc !== undefined) {
            this.currentSystem.removeFunction(oldFunc);
        }
    }
    setParticleFunctionDisableState(disable, type, id) {
        if (this.currentSystem) {
            this.currentSystem.setFunctionDisableState(disable, type, id);
        }
    }
    updateFunctionOrder(type, listElem) {
        const newOrder = [];
        for (const child of listElem.children) {
            if (!child.classList.contains("FunctionButton"))
                continue;
            const id = child.id;
            if (isNumeric(id))
                newOrder.push(parseInt(id));
        }
        this.currentSystem?.updateOrder(type, newOrder);
    }
    startSystem() {
        if (!this.currentSystem) {
            this.currentSystem = new ParticleSystem(this, "Preview", this.controlPoints, true);
            this.currentSystem.setupDefaultValues();
            this.currentSystem.start();
        }
    }
    addParticleFunction(oldId, name, args, forceDisable = false) {
        if (!this.currentSystem) {
            this.currentSystem = new ParticleSystem(this, "Preview", this.controlPoints, true);
            this.currentSystem.start();
        }
        let oldFunc = GetFunctionFromID(oldId);
        let oldDisabled = false;
        if (oldFunc !== undefined) {
            oldDisabled = this.currentSystem.removeFunction(oldFunc);
        }
        if (forceDisable)
            oldDisabled = forceDisable;
        let id = 0;
        if (name in ParticleInitializer) {
            const initName = name;
            const initializer = ParticleInitializer[initName];
            id = this.currentSystem.addFunction(new initializer(args), oldDisabled);
        }
        if (name in ParticleOperator) {
            const opName = name;
            const operator = ParticleOperator[opName];
            id = this.currentSystem.addFunction(new operator(args), oldDisabled);
        }
        if (name in ParticleEmitter) {
            const emitName = name;
            const emitter = ParticleEmitter[emitName];
            id = this.currentSystem.addFunction(new emitter(args), oldDisabled);
        }
        if (name in ParticleRenderer) {
            const rendererName = name;
            const renderer = ParticleRenderer[rendererName];
            id = this.currentSystem.addFunction(new renderer(args), oldDisabled);
        }
        if (name in ParticleForces) {
            const forceName = name;
            const force = ParticleForces[forceName];
            id = this.currentSystem.addFunction(new force(args), oldDisabled);
        }
        return id;
    }
    setParticleProperty(name, value, fromInput = false) {
        if (!this.currentSystem)
            return;
        if (fromInput) {
            if (this.controlPanel)
                this.controlPanel.setPropertyValue(name, value);
            value = ParticleFunctions.parseVal(ParticlePropertiesTypes[name], value);
        }
        this.currentSystem.setBaseProperty(name, value);
    }
    saveSceneToUrl(pSystem) {
        const urlParams = new URLSearchParams(window.location.search);
        const propertyData = pSystem.getProperties();
        const emitterData = pSystem.getEmitter();
        if (emitterData !== "{}") {
            urlParams.set("Emitter" /* FunctionType.Emitter */, emitterData);
        }
        else
            urlParams.delete("Emitter" /* FunctionType.Emitter */);
        const rendererData = pSystem.getRenderer();
        if (rendererData !== "{}") {
            urlParams.set("Renderer" /* FunctionType.Renderer */, rendererData);
        }
        else
            urlParams.delete("Renderer" /* FunctionType.Renderer */);
        const initializerData = pSystem.getInitializer();
        if (initializerData !== "{}") {
            urlParams.set("Initializer" /* FunctionType.Initializer */, initializerData);
        }
        else
            urlParams.delete("Initializer" /* FunctionType.Initializer */);
        const operatorData = pSystem.getOperator();
        if (operatorData !== "{}") {
            urlParams.set("Operator" /* FunctionType.Operator */, operatorData);
        }
        else
            urlParams.delete("Operator" /* FunctionType.Operator */);
        const forceData = pSystem.getForces();
        if (forceData !== "{}") {
            urlParams.set("Force" /* FunctionType.Force */, forceData);
        }
        else
            urlParams.delete("Force" /* FunctionType.Force */);
        if (propertyData !== "{}") {
            urlParams.set("Properties", propertyData);
        }
        else
            urlParams.delete("Properties");
        window.history.pushState("", "", location.protocol +
            "//" +
            location.host +
            location.pathname +
            "?" +
            urlParams.toString());
    }
    async loadSceneFromUrl() {
        await this.ensureShadersLoaded();
        if (!this.currentSystem) {
            this.currentSystem = new ParticleSystem(this, "Preview", this.controlPoints, true);
        }
        const urlParams = new URLSearchParams(window.location.search);
        const initializerData = urlParams.get("Initializer" /* FunctionType.Initializer */);
        if (initializerData)
            this.loadFuncFromUrlString("Initializer" /* FunctionType.Initializer */, initializerData);
        const operatorData = urlParams.get("Operator" /* FunctionType.Operator */);
        if (operatorData)
            this.loadFuncFromUrlString("Operator" /* FunctionType.Operator */, operatorData);
        const emitterData = urlParams.get("Emitter" /* FunctionType.Emitter */);
        if (emitterData)
            this.loadFuncFromUrlString("Emitter" /* FunctionType.Emitter */, emitterData);
        const rendererData = urlParams.get("Renderer" /* FunctionType.Renderer */);
        if (rendererData)
            this.loadFuncFromUrlString("Renderer" /* FunctionType.Renderer */, rendererData);
        const forceData = urlParams.get("Force" /* FunctionType.Force */);
        if (forceData)
            this.loadFuncFromUrlString("Force" /* FunctionType.Force */, forceData);
        const propertyData = urlParams.get("Properties");
        if (propertyData)
            this.loadPropertyFromString(propertyData);
        if (emitterData || rendererData || initializerData || operatorData || forceData) {
            if (this.controlPanel)
                this.controlPanel.forceOpenControl();
        }
        setTimeout(() => {
            if (this.currentSystem) {
                this.currentSystem.shouldStart = true;
                this.currentSystem.start();
            }
        }, 50);
        return true;
    }
    loadFuncFromUrlString(type, data) {
        let match;
        const re = /{?(.+?)}?(;|$)/g;
        while ((match = re.exec(data))) {
            const nameMatch = match[1].match(/(\w+)(!)?\((.*)\)/);
            if (nameMatch) {
                const name = nameMatch[1];
                const args = {};
                const argRe = /(\w+):([\w. \-+\\]+)/g;
                let argMatch;
                const disabled = nameMatch[2] !== undefined;
                while ((argMatch = argRe.exec(nameMatch[3]))) {
                    args[argMatch[1]] = argMatch[2];
                }
                if (this.controlPanel)
                    this.controlPanel.loadFunction(type, name, args, disabled);
            }
        }
    }
    loadPropertyFromString(data) {
        let re = /(\w+):([\w. \-+]+)/g;
        let match;
        while ((match = re.exec(data))) {
            this.setParticleProperty(match[1], match[2], true);
        }
    }
    drawGrid() {
        let lMaterial = new THREE.LineBasicMaterial({ color: "white" });
        lMaterial.linewidth = 2;
        for (let x = -5; x <= 5; x++) {
            let points = [];
            if (x === 0) {
                let otherMaterial = new THREE.LineBasicMaterial({
                    color: "green",
                });
                otherMaterial.linewidth = 4;
                points.push(new THREE.Vector3(x, 0, 0));
                points.push(new THREE.Vector3(x, 5, 0));
                let lGeometry = new THREE.BufferGeometry().setFromPoints(points);
                let line = new THREE.Line(lGeometry, otherMaterial);
                this.scene.add(line);
                this.lines.push(line);
                points = [];
                points.push(new THREE.Vector3(x, -5, 0));
                points.push(new THREE.Vector3(x, 0, 0));
                lGeometry = new THREE.BufferGeometry().setFromPoints(points);
                line = new THREE.Line(lGeometry, lMaterial);
                this.scene.add(line);
                this.lines.push(line);
            }
            else {
                points.push(new THREE.Vector3(x, -5, 0));
                points.push(new THREE.Vector3(x, 5, 0));
                const lGeometry = new THREE.BufferGeometry().setFromPoints(points);
                const line = new THREE.Line(lGeometry, lMaterial);
                this.scene.add(line);
                this.lines.push(line);
            }
        }
        for (let y = -5; y <= 5; y++) {
            let points = [];
            if (y === 0) {
                let otherMaterial = new THREE.LineBasicMaterial({
                    color: "red",
                });
                otherMaterial.linewidth = 4;
                points.push(new THREE.Vector3(0, y, 0));
                points.push(new THREE.Vector3(5, y, 0));
                let lGeometry = new THREE.BufferGeometry().setFromPoints(points);
                let line = new THREE.Line(lGeometry, otherMaterial);
                this.scene.add(line);
                this.lines.push(line);
                points = [];
                points.push(new THREE.Vector3(-5, y, 0));
                points.push(new THREE.Vector3(0, y, 0));
                lGeometry = new THREE.BufferGeometry().setFromPoints(points);
                line = new THREE.Line(lGeometry, lMaterial);
                this.scene.add(line);
                this.lines.push(line);
            }
            else {
                points.push(new THREE.Vector3(-5, y, 0));
                points.push(new THREE.Vector3(5, y, 0));
                const lGeometry = new THREE.BufferGeometry().setFromPoints(points);
                const line = new THREE.Line(lGeometry, lMaterial);
                this.scene.add(line);
                this.lines.push(line);
            }
        }
    }
    hideGrid() {
        for (const line of this.lines) {
            this.scene.remove(line);
        }
    }
    showGrid() {
        for (const line of this.lines) {
            this.scene.add(line);
        }
    }
}
export class ParticleFrame {
    constructor(root) {
        this.scene = new ParticleScene({
            isEditor: false,
            rootElement: root,
        });
    }
    async loadJson(info) {
        await this.scene.ensureShadersLoaded();
        await this.scene.loadJson(info);
    }
    async loadJsonPath(path) {
        await this.scene.ensureShadersLoaded();
        await this.scene.loadJsonPath(path);
    }
    zoom(amount) {
        this.scene.manualZoom(amount);
    }
    moveCamera(diff) {
        this.scene.manualMove(diff);
    }
    test() {
        this.scene.ensureShadersLoaded().then(() => {
            // this.scene.drawGrid();
            this.scene.manualZoom(12);
            this.scene.manualMove(new Vector(0, 0, 3));
            const system = this.scene.getRootSystem();
            if (!system)
                return;
            system.addFunction(new ParticleEmitter.EmitContinously({
                speed: 100,
            }));
            system.addFunction(new ParticleInitializer.ColorRandom({
                startColor: new Color(255, 0, 0),
                endColor: new Color(225, 60, 0),
            }));
            system.addFunction(new ParticleInitializer.PositionWithinSphereRandom({
                cpNumber: 1,
                minRadius: 0.1,
                maxRadius: 0.1,
            }));
            system.addFunction(new ParticleOperator.LifespanDecay({}));
            system.addFunction(new ParticleOperator.AlphaFadeOut({}));
            system.addFunction(new ParticleOperator.MovementBasic({
                gravity: new Vector(0, 0, 2),
                drag: 0.05,
            }));
            system.addFunction(new ParticleOperator.RadiusScale({
                endScale: 0,
                startTime: 0.6,
            }));
            system.addFunction(new ParticleRenderer.RenderSprites({
                overbright: 5,
            }));
            system.addFunction(new ParticleForces.PullTowardsControlPoint({
                cpNumber: 1,
                pullForce: -3,
            }));
            // system.controlPoints.setPosition(1, new Vector(5, 0, 0));
            system.shouldStart = true;
            system.start();
        });
    }
}
