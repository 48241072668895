import * as THREE from "three";
import { Euler, MathUtils, ShaderMaterial } from "three";
import { BaseParticleFunction } from "./particles_operators";
import { SpriteTexture } from "./sprite_loader";
import { EndCapState, getModelFromName, getShader, getTextureFromName } from "./util";
export var RendererOrientation;
(function (RendererOrientation) {
    RendererOrientation["ScreenAlign"] = "Screen Align";
    RendererOrientation["WorldZAlign"] = "World-Z Align";
    RendererOrientation["NormalAlign"] = "Normal Align";
})(RendererOrientation || (RendererOrientation = {}));
export var RendererBlendType;
(function (RendererBlendType) {
    RendererBlendType["AlphaBlend"] = "Alpha Blend";
    RendererBlendType["Additive"] = "Additive";
})(RendererBlendType || (RendererBlendType = {}));
export class BaseParticleRenderer extends BaseParticleFunction {
    constructor() {
        super("Renderer" /* FunctionType.Renderer */);
        this.endCapState = EndCapState.ALWAYS;
    }
    isRenderer() {
        return true;
    }
    onDestroy() {
        if (!this.system)
            return;
        const scene = this.system.scene.getRenderScene();
        for (const particle of this.system.particles) {
            if (particle.object) {
                scene.remove(particle.object);
            }
        }
    }
}
export var ParticleRenderer;
(function (ParticleRenderer) {
    class RenderSprites extends BaseParticleRenderer {
        constructor(options) {
            super();
            this.textureName = "DEFAULT_SPRITE";
            this.orientation = RendererOrientation.ScreenAlign;
            this.blendType = RendererBlendType.AlphaBlend;
            this.animated = false;
            this.animationSpeed = 20;
            this.ticks = 0;
            this.smoothness = 1.5;
            // Color options
            this.overbright = 0;
            if (options) {
                this.textureName = options.texture ?? this.textureName;
                this.texture = new SpriteTexture(this.textureName);
                this.orientation = options.orientation ?? this.orientation;
                this.blendType = options.blendType ?? this.blendType;
                this.animated = options.animated ?? this.animated;
                this.animationSpeed = 20 * (20 / (options.animationSpeed ?? this.animationSpeed));
                this.overbright = options.overbright ?? this.overbright;
            }
        }
        static getArgs() {
            return {
                texture: ["Texture" /* FieldTypes.Texture */, "DEFAULT_SPRITE"],
                orientation: ["Orientation" /* FieldTypes.Orientation */, RendererOrientation.ScreenAlign],
                blendType: ["BlendType" /* FieldTypes.BlendType */, RendererBlendType.AlphaBlend],
                animated: ["Bool" /* FieldTypes.Boolean */, true],
                animationSpeed: ["Number" /* FieldTypes.Number */, 20],
                overbright: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                texture: this.textureName,
                orientation: this.orientation,
                blendType: this.blendType,
                animated: this.animated,
                animationSpeed: this.animationSpeed,
                overbright: this.overbright,
            };
        }
        render(particleList, scene, camera) {
            this.ticks++;
            if (this.disabled)
                return;
            if (!this.texture)
                return;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                let mesh;
                if (!particle.object) {
                    const planeGeometry = new THREE.PlaneGeometry(1, 1, 1, 1);
                    let plane;
                    if (this.texture.type != 2 /* SpriteSheetType.Animated */) {
                        const texture = this.texture.getTexture(particle.sequence);
                        if (!texture)
                            return;
                        // const planeMaterial = new THREE.MeshBasicMaterial({
                        // 	map: texture,
                        // 	transparent: true,
                        // 	side: THREE.DoubleSide,
                        // 	depthWrite: false,
                        // });
                        const shaderUniforms = {
                            tex: {
                                type: "t",
                                value: texture,
                            },
                            overbright: {
                                value: this.overbright,
                            },
                            alpha: {
                                value: particle.alpha / 255,
                            },
                            blendColor: {
                                value: particle.color.exportRaw(),
                            },
                            additive: {
                                value: this.blendType == RendererBlendType.Additive,
                            },
                        };
                        const shaderMaterial = new ShaderMaterial({
                            uniforms: shaderUniforms,
                            vertexShader: getShader("basicSprite.vertex"),
                            fragmentShader: getShader("basicSprite.fragment"),
                            transparent: true,
                            // blending: THREE.AdditiveBlending,
                            side: THREE.DoubleSide,
                            depthWrite: false,
                        });
                        plane = new THREE.Mesh(planeGeometry, shaderMaterial);
                        // plane = new THREE.Mesh(planeGeometry, planeMaterial);
                    }
                    else {
                        this.texture.precacheFrameTextures(particle.sequence);
                        const shaderUniforms = {
                            texture1: {
                                type: "t",
                                value: new THREE.TextureLoader().load(this.texture.getFrame(particle.sequence, 0)),
                            },
                            texture2: {
                                type: "t",
                                value: new THREE.TextureLoader().load(this.texture.getFrame(particle.sequence, 1)),
                            },
                            texture3: {
                                type: "t",
                                value: new THREE.TextureLoader().load(this.texture.getFrame(particle.sequence, 2 % this.texture.getFrameCount(particle.sequence))),
                            },
                            blend: {
                                value: 0.0,
                            },
                            smoothness: {
                                value: this.smoothness,
                            },
                            offset: {
                                value: [0, 0],
                            },
                            scale: {
                                value: [1, 1],
                            },
                        };
                        const shaderMaterial = new ShaderMaterial({
                            uniforms: shaderUniforms,
                            vertexShader: getShader("basicSprite.vertex"),
                            fragmentShader: getShader("animatedSprite.fragment"),
                            transparent: true,
                            side: THREE.DoubleSide,
                            depthWrite: false,
                        });
                        plane = new THREE.Mesh(planeGeometry, shaderMaterial);
                    }
                    particle.object = plane;
                    scene.add(plane);
                    mesh = plane;
                }
                else {
                    mesh = particle.object;
                }
                if (this.texture.type == 2 /* SpriteSheetType.Animated */ && this.animated) {
                    const shaderMaterial = mesh.material;
                    const smoothness = 1;
                    let blendAmount = (this.ticks % (this.animationSpeed * 3)) / this.animationSpeed;
                    shaderMaterial.uniforms["blend"].value = blendAmount;
                    const maxFrames = this.texture.getFrameCount(particle.sequence);
                    const frame = Math.floor(this.ticks / this.animationSpeed) % maxFrames;
                    const nextFrame = (frame + 1) % maxFrames;
                    const getTexture = (frame) => {
                        if (!this.texture)
                            return;
                        const tex = this.texture.getFrameTexture(particle.sequence, frame);
                        if (!tex)
                            return;
                        return tex;
                    };
                    if (this.lastIndex == undefined || frame != this.lastIndex) {
                        let blendFrames = [undefined, nextFrame, undefined];
                        if (blendAmount >= 1 && blendAmount < 2) {
                            blendFrames = [undefined, undefined, nextFrame];
                        }
                        else if (blendAmount >= 2) {
                            blendFrames = [nextFrame, undefined, undefined];
                        }
                        if (blendFrames[0]) {
                            shaderMaterial.uniforms["texture1"].value = getTexture(blendFrames[0]);
                        }
                        if (blendFrames[1]) {
                            shaderMaterial.uniforms["texture2"].value = getTexture(blendFrames[1]);
                        }
                        if (blendFrames[2]) {
                            shaderMaterial.uniforms["texture3"].value = getTexture(blendFrames[2]);
                        }
                        this.lastIndex = frame;
                        // shaderMaterial.needsUpdate = true;
                    }
                }
                mesh.position.set(particle.position.x, particle.position.y, particle.position.z);
                mesh.scale.set(particle.radius, particle.radius, particle.radius);
                // const material = mesh.material as THREE.MeshBasicMaterial;
                // material.color = particle.color.export();
                // material.opacity = particle.alpha / 255;
                const material = mesh.material;
                material.uniforms.overbright.value = this.overbright;
                material.uniforms.blendColor.value = particle.color.exportRaw();
                material.uniforms.alpha.value = particle.alpha / 255;
                switch (this.orientation) {
                    case RendererOrientation.ScreenAlign:
                        mesh.quaternion.copy(camera.quaternion);
                        mesh.rotateZ(MathUtils.degToRad(particle.roll));
                        mesh.rotateX(MathUtils.degToRad(particle.pitch));
                        mesh.rotateY(MathUtils.degToRad(particle.yaw));
                        break;
                    case RendererOrientation.WorldZAlign:
                    case RendererOrientation.NormalAlign:
                        mesh.setRotationFromEuler(new Euler(MathUtils.degToRad(particle.pitch), MathUtils.degToRad(particle.yaw), MathUtils.degToRad(particle.roll)));
                        break;
                }
            }
        }
    }
    ParticleRenderer.RenderSprites = RenderSprites;
    class RenderSpriteTrails extends BaseParticleRenderer {
        constructor(options) {
            super();
            this.textureName = "DEFAULT_SPRITE";
            this.orientation = RendererOrientation.ScreenAlign;
            this.minLength = 0;
            this.maxLength = 0;
            if (options) {
                this.textureName = options.texture ?? this.textureName;
                this.texture = getTextureFromName(this.textureName);
                this.orientation = options.orientation ?? this.orientation;
                this.minLength = options.minLength ?? this.minLength;
                this.maxLength = options.maxLength ?? this.maxLength;
            }
        }
        static getArgs() {
            return {
                texture: ["Texture" /* FieldTypes.Texture */, "DEFAULT_SPRITE"],
                orientation: ["Orientation" /* FieldTypes.Orientation */, RendererOrientation.ScreenAlign],
                minLength: ["Number" /* FieldTypes.Number */, 0],
                maxLength: ["Number" /* FieldTypes.Number */, 10],
            };
        }
        getOptions() {
            return {
                texture: this.textureName,
                orientation: this.orientation,
                minLength: this.minLength,
                maxLength: this.maxLength,
            };
        }
        render(particleList, scene, camera) {
            if (this.disabled)
                return;
            if (!this.texture)
                return;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                let mesh;
                if (!particle.object) {
                    const planeGeometry = new THREE.PlaneGeometry(1, 1, 1, 1);
                    const planeMaterial = new THREE.MeshBasicMaterial({
                        map: this.texture,
                        transparent: true,
                        side: THREE.DoubleSide,
                        depthWrite: false,
                    });
                    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
                    particle.object = plane;
                    scene.add(plane);
                    mesh = plane;
                }
                else {
                    mesh = particle.object;
                }
                mesh.position.set(particle.position.x, particle.position.y, particle.position.z);
                let stretch = Math.abs(particle.velocity.x) +
                    Math.abs(particle.velocity.y) +
                    Math.abs(particle.velocity.z);
                stretch *= particle.trailLength;
                stretch = Math.max(this.minLength, Math.min(this.maxLength, stretch));
                mesh.scale.set(particle.radius, stretch, particle.radius);
                const material = mesh.material;
                material.color = particle.color.export();
                material.opacity = particle.alpha / 255;
                const lookPos = particle.position.add(particle.velocity);
                mesh.lookAt(lookPos.exportThree());
                mesh.rotateZ(MathUtils.degToRad(90));
                mesh.rotateX(MathUtils.degToRad(-90));
                switch (this.orientation) {
                    case RendererOrientation.ScreenAlign:
                        const forward = new THREE.Vector3();
                        forward
                            .subVectors(camera.position, particle.position.exportThree())
                            .normalize();
                        mesh.up = forward.clone();
                        mesh.rotateY(MathUtils.degToRad(90));
                        break;
                }
            }
        }
    }
    ParticleRenderer.RenderSpriteTrails = RenderSpriteTrails;
    class RenderRope extends BaseParticleRenderer {
        constructor(options) {
            super();
            this.textureName = "DEFAULT_SPRITE";
            this.orientation = RendererOrientation.WorldZAlign;
            this.ticks = 0;
            this.started = false;
            if (options) {
                this.textureName = options.texture ?? this.textureName;
                this.orientation = options.orientation ?? this.orientation;
            }
        }
        static getArgs() {
            return {
                texture: ["Texture" /* FieldTypes.Texture */, "DEFAULT_SPRITE"],
                orientation: ["Orientation" /* FieldTypes.Orientation */, RendererOrientation.WorldZAlign],
            };
        }
        getOptions() {
            return { texture: this.textureName, orientation: this.orientation };
        }
        async onInit() {
            // this.model = await getModelFromName("DEFAULT_MODEL");
            // // this.model = await getModelFromName("big_sword");
            // let material = this.model!.material as THREE.MeshStandardMaterial;
            // material.metalness = 0;
        }
        render(particleList, scene, camera) {
            if (this.disabled)
                return;
            if (!this.textureName)
                return;
            this.ticks++;
            if (this.ticks > 50) {
                const curvePoints = [];
                for (let index = 0; index < particleList.length; index++) {
                    const particle = particleList[index];
                    curvePoints.push(particle.position.exportThree());
                }
                if (curvePoints.length < 1)
                    return;
                const curve = new THREE.CatmullRomCurve3(curvePoints, true, undefined, 0.1);
                const amount = 100;
                const radius = 0.2;
                try {
                    const frenetFrames = curve.computeFrenetFrames(amount, true);
                    const spacedPoints = curve.getSpacedPoints(amount);
                    let point = new THREE.Vector3();
                    let finalPoints = [];
                    const dimensions = [-radius, radius];
                    dimensions.forEach((d) => {
                        for (let i = 0; i <= amount; i++) {
                            point = spacedPoints[i];
                            let binormalShift = new THREE.Vector3();
                            if (this.orientation == RendererOrientation.WorldZAlign) {
                                binormalShift.add(frenetFrames.binormals[i]).multiplyScalar(d);
                            }
                            else if (this.orientation == RendererOrientation.NormalAlign) {
                                binormalShift.add(frenetFrames.normals[i]).multiplyScalar(d);
                            }
                            else if (this.orientation == RendererOrientation.ScreenAlign) {
                                const forward = new THREE.Vector3();
                                forward.subVectors(camera.position, point).normalize();
                                let normal = frenetFrames.normals[i];
                                normal = forward.applyAxisAngle(frenetFrames.tangents[i], 45);
                                binormalShift.add(normal).multiplyScalar(d);
                            }
                            finalPoints.push(new THREE.Vector3().copy(point).add(binormalShift));
                        }
                    });
                    const tempPlane = new THREE.PlaneBufferGeometry(1, 1, amount, 1);
                    finalPoints.push();
                    tempPlane.setFromPoints(finalPoints);
                    if (!this.mesh) {
                        const tex = getTextureFromName(this.textureName);
                        // const texture = new SpriteTexture(this.textureName);
                        // const tex = texture.getTexture(0)!;
                        tex.rotation = MathUtils.degToRad(90);
                        tex.wrapS = 1000;
                        tex.wrapT = 1000;
                        tex.repeat.set(1, 10);
                        tex.offset.setX(0.5);
                        const material = new THREE.MeshBasicMaterial({
                            map: tex,
                            side: THREE.DoubleSide,
                            transparent: true,
                            depthTest: false,
                        });
                        this.mesh = new THREE.Mesh(tempPlane, new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true }));
                        // this.mesh = new THREE.Mesh(tempPlane, material);
                        // console.log("ADD!");
                        scene.add(this.mesh);
                    }
                    else {
                        this.mesh.geometry = tempPlane;
                    }
                }
                catch (e) {
                    console.log("Error?");
                    console.log(e);
                }
            }
        }
    }
    ParticleRenderer.RenderRope = RenderRope;
    class RenderModels extends BaseParticleRenderer {
        constructor(options) {
            super();
            this.modelName = "DEFAULT_MODEL";
            if (options) {
                this.modelName = options.model ?? this.modelName;
            }
        }
        static getArgs() {
            return {
                model: ["Model" /* FieldTypes.Model */, "DEFAULT_MODEL"],
            };
        }
        getOptions() {
            return { model: this.modelName };
        }
        async onInit() {
            this.model = await getModelFromName("DEFAULT_MODEL");
            // this.model = await getModelFromName("big_sword");
            let material = this.model.material;
            material.metalness = 0;
        }
        render(particleList, scene, camera) {
            if (this.disabled)
                return;
            if (!this.model)
                return;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                let mesh;
                if (!particle.object) {
                    mesh = this.model.clone();
                    mesh.material = this.model.material.clone();
                    scene.add(mesh);
                    particle.object = mesh;
                }
                else {
                    mesh = particle.object;
                }
                mesh.position.set(particle.position.x, particle.position.y, particle.position.z);
                mesh.scale.set(particle.radius, particle.radius, particle.radius);
                const material = mesh.material;
                material.color = particle.color.export();
                material.opacity = particle.alpha / 255;
                // console.log(particle.pitch, particle.pitchSpeed);
                mesh.setRotationFromEuler(new Euler(MathUtils.degToRad(particle.roll), MathUtils.degToRad(particle.pitch), MathUtils.degToRad(particle.yaw)));
                // mesh.quaternion.copy(camera.quaternion);
            }
        }
    }
    ParticleRenderer.RenderModels = RenderModels;
})(ParticleRenderer || (ParticleRenderer = {}));
