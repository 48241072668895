var LetterStatus;
(function (LetterStatus) {
    LetterStatus[LetterStatus["NORMAL"] = 0] = "NORMAL";
    LetterStatus[LetterStatus["CONTAIN"] = 1] = "CONTAIN";
    LetterStatus[LetterStatus["CORRECT"] = 2] = "CORRECT";
})(LetterStatus || (LetterStatus = {}));
class WordleCorpus {
    constructor() {
        const importData = require("../../res/wordle/corpusData.json");
        if (!importData)
            throw Error("!");
        this.data = importData;
        this.allWords = this.data["all"][0];
    }
    getOptions(containedLetters, correctLetters, excludedLetters, curWord, max) {
        let options = [];
        let corrects = [];
        // Insert words with correct letter positions
        for (const [_index, letter] of Object.entries(correctLetters)) {
            const index = parseInt(_index);
            corrects.push(this.getWordList(letter, index));
        }
        // Insert words containing the current search string
        let index = 0;
        for (const letter of curWord) {
            corrects.push(this.getWordList(letter, index));
            if (containedLetters.includes(letter)) {
                containedLetters = containedLetters.filter((l) => l !== letter);
            }
            index++;
        }
        // Insert words containing correct letters (but no correct position)
        index = 0;
        for (const contained of containedLetters) {
            corrects.push(this.getWordWithLetter(contained, index));
            index++;
        }
        // Remove words that are not in all lists
        if (corrects.length > 0) {
            options = this.findCommon(corrects);
        }
        else {
            options = this.getAllWords();
        }
        // Remove Words that have excluded letters
        let removeWords = [];
        for (const option of options) {
            for (const letter of excludedLetters) {
                if (option.indexOf(letter) >= 0) {
                    removeWords.push(option);
                }
            }
        }
        options = options.filter((word) => !removeWords.includes(word));
        // Remove words that have contained letters at false positions
        removeWords = [];
        for (const option of options) {
            for (const [_index, letter] of Object.entries(correctLetters)) {
                const index = parseInt(_index);
                const optionLetter = option[index];
                if (optionLetter !== letter)
                    removeWords.push(option);
            }
        }
        options = options.filter((word) => !removeWords.includes(word));
        // get the maximum of options
        if (!max)
            return options;
        return options.slice(0, max);
    }
    getRandomWord() {
        const alphabet = "abcdefghijklmnopqrstuvwxyz";
        const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)];
        const randomIndex = Math.floor(Math.random() * 5);
        const wordList = this.getWordList(randomCharacter, randomIndex);
        return wordList[Math.floor(Math.random() * wordList.length)];
    }
    getAllWords() {
        const wordList = [];
        for (const word of this.allWords) {
            wordList.push(word);
        }
        return wordList;
    }
    getWordList(letter, index) {
        if (!this.data[letter.toLowerCase()])
            return [];
        const result = this.data[letter.toLowerCase()][index] ?? [];
        return result;
    }
    getWordWithLetter(letter, index) {
        let result = [];
        const data = this.data[letter.toLowerCase()] ?? [];
        if (!data)
            return [];
        for (const [_index, words] of Object.entries(data)) {
            if (parseInt(_index) === index)
                continue;
            result = result.concat(words);
        }
        return result;
    }
    findCommon(list) {
        const result = list.shift().filter((v) => {
            return list.every((a) => {
                return a.indexOf(v) !== -1;
            });
        });
        return result;
    }
}
export class Wordle {
    constructor() {
        this.rows = {};
        this.curRow = 1;
        this.containedLetters = [];
        this.correctLetters = {};
        this.excludedLetters = [];
        this.corpus = new WordleCorpus();
        const input = document.getElementById("Input");
        if (!input)
            throw Error("Couldn't find Input element!");
        this.input = input;
        this.input.addEventListener("input", (event) => {
            this.onInputChange(this.input.value, this.input.value);
        });
        const firstRow = document.getElementById("Row1");
        if (!firstRow)
            throw Error("Couldn't find preview!");
        this.rows[1] = [];
        for (const children of firstRow.children) {
            this.rows[1].push({
                status: LetterStatus.NORMAL,
                content: "",
                element: children,
            });
        }
        const rowsContainer = document.getElementById("Rows");
        if (!rowsContainer)
            throw Error("Couldn't find Rows element!");
        this.rowsContainer = rowsContainer;
        for (const child of firstRow.children) {
            this.setRowElementAction(child);
        }
        const options = document.getElementById("Options");
        if (!options)
            throw Error("Couldn't find options element!");
        this.options = options;
        const autoChoose = document.getElementById("AutoChoose");
        if (!autoChoose)
            throw Error("Couldn't find autoChoose element!");
        autoChoose.addEventListener("click", (event) => {
            let word = "";
            if (this.getCurWord(true) === "" && this.curRow === 1) {
                word = this.corpus.getRandomWord();
            }
            else {
                const options = this.corpus.getOptions(this.containedLetters, this.correctLetters, this.excludedLetters, this.getCurWord(true));
                word = options[Math.floor(Math.random() * options.length)];
            }
            if (word !== "")
                this.onInputChange(word, this.input.value);
        });
        const confirm = document.getElementById("Confirm");
        if (!confirm)
            throw Error("Couldn't find confirm element!");
        this.confirm = confirm;
        confirm.addEventListener("click", (event) => {
            if (confirm.classList.contains("locked"))
                return;
            this.newRow();
        });
        const setInput = document.getElementById("SetInput");
        if (!setInput)
            throw Error("Couldn't find setInput element!");
        setInput.addEventListener("click", (event) => {
            this.onInputChange(this.input.value, this.input.value);
        });
        const loadInput = document.getElementById("LoadInput");
        if (!loadInput)
            throw Error("Couldn't find loadInput element!");
        loadInput.addEventListener("click", (event) => {
            const word = this.getCurWord();
            this.input.value = word;
            this.onInputChange(word, word);
        });
    }
    setRowElementAction(child) {
        child.addEventListener("click", (event) => {
            const letter = this.getLetterObject(child);
            if (!letter)
                return;
            if (letter.content === "")
                return;
            const rowIndex = this.getRowIndex(child);
            if (rowIndex !== this.curRow)
                return;
            const curStatus = letter.status;
            switch (curStatus) {
                case LetterStatus.NORMAL:
                    letter.status = LetterStatus.CONTAIN;
                    child.classList.remove("correct");
                    child.classList.add("contain");
                    break;
                case LetterStatus.CONTAIN:
                    letter.status = LetterStatus.CORRECT;
                    child.classList.remove("contain");
                    child.classList.add("correct");
                    break;
                case LetterStatus.CORRECT:
                    letter.status = LetterStatus.NORMAL;
                    child.classList.remove("contain");
                    child.classList.remove("correct");
                    break;
            }
            this.checkConfirm();
        });
    }
    getLetterObject(element) {
        for (const row of Object.values(this.rows)) {
            for (const letter of row) {
                if (letter.element === element)
                    return letter;
            }
        }
        return;
    }
    getRowIndex(element) {
        const parent = element.parentElement;
        if (!parent)
            return 1;
        const lastChar = parent.id.slice(-1);
        return parseInt(lastChar);
    }
    onInputChange(input, origInput) {
        if (!input) {
            input = "";
        }
        const curRow = this.rows[this.curRow];
        for (let index = 0; index < 5; index++) {
            if (index > input.length)
                break;
            const inputLetter = input[index];
            const letterObj = curRow[index];
            if (inputLetter) {
                letterObj.element.innerHTML = inputLetter;
                letterObj.content = inputLetter.toLowerCase();
            }
            else {
                letterObj.element.innerHTML = "";
                letterObj.element.classList.remove("contain");
                letterObj.element.classList.remove("correct");
                letterObj.content = "";
            }
            if (origInput[index] && origInput[index].toLowerCase() === inputLetter.toLowerCase()) {
                letterObj.element.classList.add("orig");
            }
            else {
                letterObj.element.classList.remove("orig");
            }
        }
        this.checkConfirm();
        const options = this.corpus.getOptions(this.containedLetters, this.correctLetters, this.excludedLetters, this.getCurWord(true), 50);
        clearChildren(this.options);
        for (const option of options) {
            if (option.toLowerCase() === input.toLowerCase())
                continue;
            const newOption = document.createElement("div");
            newOption.classList.add("Option");
            newOption.innerHTML = option;
            newOption.addEventListener("click", (event) => {
                this.onInputChange(newOption.innerHTML, origInput);
            });
            this.options.appendChild(newOption);
        }
    }
    getCurWord(useOnlyOrigLetters = false) {
        let word = "";
        const curRow = this.rows[this.curRow];
        for (const letter of curRow) {
            if (useOnlyOrigLetters) {
                if (!letter.element.classList.contains("orig"))
                    continue;
            }
            word += letter.content;
        }
        return word;
    }
    checkConfirm() {
        const curRow = this.rows[this.curRow];
        for (let index = 0; index < 5; index++) {
            const letterObj = curRow[index];
            if (letterObj.status !== LetterStatus.NORMAL) {
                if (letterObj.status === LetterStatus.CONTAIN) {
                    if (!this.containedLetters.includes(letterObj.content)) {
                        this.containedLetters.push(letterObj.content);
                    }
                }
                if (letterObj.status === LetterStatus.CORRECT) {
                    this.correctLetters[index] = letterObj.content;
                }
            }
        }
        if (this.getCurWord().length === 5 && this.curRow < 5) {
            this.confirm.classList.remove("locked");
        }
        else {
            this.confirm.classList.add("locked");
        }
    }
    newRow() {
        const newRowIndex = this.curRow + 1;
        if (newRowIndex > 6) {
            return;
        }
        const curWord = this.getCurWord();
        for (const letter of curWord) {
            if (this.containedLetters.includes(letter))
                continue;
            if (Object.keys(this.correctLetters).includes(letter))
                continue;
            if (!this.excludedLetters.includes(letter.toLowerCase()))
                this.excludedLetters.push(letter.toLowerCase());
        }
        const newRow = document.createElement("div");
        newRow.setAttribute("id", "Row" + newRowIndex);
        newRow.classList.add("Preview");
        this.rowsContainer.appendChild(newRow);
        this.rows[newRowIndex] = [];
        for (let index = 0; index < 5; index++) {
            const previewItem = document.createElement("div");
            previewItem.classList.add("PreviewItem");
            previewItem.classList.add("noSelect");
            this.setRowElementAction(previewItem);
            newRow.appendChild(previewItem);
            this.rows[newRowIndex].push({
                content: "",
                status: LetterStatus.NORMAL,
                element: previewItem,
            });
        }
        this.curRow = newRowIndex;
        this.input.value = "";
        this.onInputChange("", "");
    }
}
function clearChildren(element) {
    while (element.firstChild) {
        element.firstChild.remove();
    }
}
