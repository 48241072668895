import { ParticleFloatAttributes, ParticlePropertiesTypes, ParticleRotationAttributes, ParticleVectorAttributes, } from "./particles_operators";
import { openColorPopup, openFunctionPopup, openSpritePopup } from "./popups";
import { clearChildren, Color, generateUniqueID, Vector } from "./util";
import { RendererBlendType, RendererOrientation } from "./particle_renderer";
import { ParticleFunctions } from "./particle_function_util";
let allButtons = {};
let allDetails = {};
export class FunctionButton {
    constructor(scene, controlPanel, parent, name, rawArgs, isBase) {
        this.name = "";
        this.selected = false;
        this.deleted = false;
        this.disabled = false;
        this.deletable = true;
        this.empty = true;
        this.dragging = false;
        this.dragIndex = 0;
        this.id = generateUniqueID().toString();
        allButtons[this.id] = this;
        this.scene = scene;
        this.controlPanel = controlPanel;
        this.element = document.createElement("div");
        this.element.classList.add("FunctionButton");
        parent.appendChild(this.element);
        if ((isBase !== undefined && isBase) || isBase === undefined) {
            this.button = document.createElement("img");
            this.button.src = "../res/add.svg";
            this.element.appendChild(this.button);
        }
        this.element.addEventListener("click", () => {
            if (!this.dragging)
                this.bigClick();
        });
        setTimeout(() => {
            this.element.classList.add("adding");
        }, 100);
        setTimeout(() => {
            this.element.classList.remove("adding");
            this.element.classList.add("added");
        }, 400);
        if (name) {
            this.loadFunction(name, rawArgs, !isBase);
        }
        else {
            this.element.classList.add("init");
        }
    }
    loadFunction(name, rawArgs, isBase) {
        this.element.classList.remove("init");
        if (!this.empty)
            return;
        this.empty = false;
        if (isBase !== undefined)
            this.deletable = isBase;
        this.name = name;
        this.details = new FunctionDetails(this.scene, this.controlPanel, this.id, rawArgs, isBase);
        this.element.setAttribute("id", this.details.getID().toString());
        if (this.groupName) {
            this.controlPanel.addEmptyFuntion(this.groupName);
        }
        if (!isBase) {
            this.checkBox = document.createElement("input");
            this.checkBox.type = "checkbox";
            this.checkBox.checked = true;
            this.element.appendChild(this.checkBox);
            this.checkBox.addEventListener("change", (e) => {
                this.cancelSelectToggle();
                if (!this.checkBox)
                    return;
                this.disabled = !this.checkBox.checked;
                if (this.details && this.groupName) {
                    this.details.disable(this.disabled, this.groupName);
                }
            });
        }
        let functionTitle = document.createElement("div");
        this.element.appendChild(functionTitle);
        functionTitle.innerHTML = name;
        if (this.deletable && this.button) {
            this.button.addEventListener("click", () => {
                this.cancelSelectToggle();
                this.remove();
            });
            this.dragButton = document.createElement("div");
            this.dragButton.classList.add("dragButton");
            this.element.appendChild(this.dragButton);
            this.dragButton.addEventListener("mousedown", (e) => {
                this.dragStart(e);
            });
            document.addEventListener("mouseup", (e) => {
                this.dragEnd();
            });
        }
        this.queueSelectToggle();
    }
    dragStart(event) {
        this.dragging = true;
        this.element.classList.add("grabbed");
        document.body.classList.add("noSelect");
        document.body.style.cursor = "grabbing";
        const parent = this.element.parentElement;
        this.placeholder = document.createElement("div");
        this.placeholder.classList.add("FunctionButtonPlaceholder");
        parent.insertBefore(this.placeholder, this.element);
        let mousePos = {
            x: event.clientX,
            y: event.clientY,
        };
        const childIndex = [...parent.children].indexOf(this.element) - 2;
        const maxIndex = parent.children.length - 3;
        const startOffset = 24 + childIndex * 30;
        let curOffset = startOffset;
        const maxOffset = parent.clientHeight - 30;
        this.element.style.setProperty("--top-offset", `${curOffset}px`);
        this.marker = document.createElement("div");
        this.marker.classList.add("DragMarker");
        parent.appendChild(this.marker);
        this.dragIndex = Math.max(0, Math.min(maxIndex, Math.floor(curOffset / 30)));
        const offset = 24 + 30 * this.dragIndex;
        this.marker.style.setProperty("--top-offset", `${offset}px`);
        const markSnapPos = () => {
            if (!this.marker)
                return;
            this.dragIndex = Math.max(0, Math.min(maxIndex, Math.floor(curOffset / 30)));
            const offset = 24 + 30 * this.dragIndex;
            this.marker.style.setProperty("--top-offset", `${offset}px`);
        };
        this.moveCallback = (e) => {
            let deltaY = mousePos.y - e.clientY;
            curOffset = startOffset - deltaY;
            const finalOffset = Math.max(0, Math.min(maxOffset, curOffset));
            markSnapPos();
            this.element.style.setProperty("--top-offset", `${finalOffset}px`);
        };
        document.addEventListener("mousemove", this.moveCallback);
    }
    dragEnd() {
        if (!this.dragging)
            return;
        if (this.placeholder) {
            this.placeholder.remove();
            this.placeholder = undefined;
        }
        if (this.marker) {
            this.marker.remove();
            this.marker = undefined;
        }
        document.body.classList.remove("noSelect");
        document.body.style.cursor = "default";
        const parent = this.element.parentElement;
        const nextElem = parent.children[this.dragIndex + 1];
        parent.insertBefore(this.element, nextElem);
        this.dragIndex = 0;
        this.scene.updateFunctionOrder(this.groupName, parent);
        setTimeout(() => {
            this.dragging = false;
        }, 50);
        this.element.classList.remove("grabbed");
        if (this.moveCallback)
            this.dragButton.removeEventListener("mousemove", this.moveCallback);
        this.moveCallback = undefined;
    }
    setGroupName(groupName) {
        this.groupName = groupName;
    }
    setProperty(name, rawVal) {
        if (!this.details)
            return;
        this.details.setProperty(name, rawVal);
    }
    setDisabledState(state) {
        if (this.disabled !== state) {
            if (this.checkBox) {
                this.checkBox.checked = !state;
            }
            this.disabled = state;
            if (this.details && this.groupName) {
                this.details.disable(this.disabled, this.groupName);
            }
        }
    }
    bigClick() {
        if (this.empty) {
            openFunctionPopup(this.scene, this.groupName ?? "Operator" /* FunctionType.Operator */, (name) => {
                this.loadFunction(name);
            });
        }
        else {
            this.queueSelectToggle();
        }
    }
    queueSelectToggle() {
        if (this.selecting)
            return;
        this.selecting = setTimeout(() => {
            if (this.element.classList.contains("selected")) {
                this.element.classList.remove("selected");
                let details = this.controlPanel.getDetailsPanel();
                details.hide();
            }
            else {
                this.element.classList.add("selected");
                this.details.show();
                for (const [id, btn] of Object.entries(allButtons)) {
                    if (id !== this.id) {
                        btn.unselect();
                    }
                }
            }
            this.selecting = undefined;
        }, 10);
    }
    cancelSelectToggle() {
        if (this.selecting) {
            clearTimeout(this.selecting);
            this.selecting = undefined;
        }
        else {
            this.selecting = setTimeout(() => {
                this.selecting = undefined;
            }, 10);
        }
    }
    unselect() {
        this.element.classList.remove("selected");
    }
    remove() {
        if (this.selecting) {
            clearTimeout(this.selecting);
        }
        this.deleted = true;
        clearChildren(this.element);
        this.element.classList.add("removing");
        delete allButtons[this.id];
        if (this.details)
            this.details.remove();
        setTimeout(() => {
            this.element.remove();
        }, 300);
    }
}
class FunctionDetails {
    constructor(scene, controlPanel, id, rawArgs, isBase = false) {
        this.fId = 0;
        this.shown = false;
        this.id = id;
        this.scene = scene;
        this.controlPanel = controlPanel;
        const fButton = allButtons[id];
        this.name = fButton.name;
        this.isBase = isBase;
        this.baseWindow = document.createElement("div");
        this.baseWindow.setAttribute("id", "FunctionDetails");
        this.baseWindow.classList.add("hidden");
        let title = document.createElement("div");
        title.classList.add("FieldTitle");
        this.baseWindow.appendChild(title);
        title.innerHTML = this.name;
        this.baseWindow.classList.add("open");
        if (!this.isBase) {
            let classArgs;
            if (fButton.groupName) {
                classArgs = ParticleFunctions.getFunctionArgs(fButton.groupName, this.name);
            }
            if (!classArgs)
                return;
            let args = {};
            for (let [name, [valType, defaultVal]] of Object.entries(classArgs)) {
                let field = document.createElement("div");
                field.classList.add("FieldBox");
                this.baseWindow.appendChild(field);
                const fieldBoxName = document.createElement("div");
                fieldBoxName.classList.add("FieldBoxName");
                fieldBoxName.innerHTML = name;
                fieldBoxName.setAttribute("title", name);
                field.appendChild(fieldBoxName);
                if (rawArgs && rawArgs[name]) {
                    defaultVal = rawArgs[name];
                }
                let fieldInput;
                switch (valType) {
                    case "Number" /* FieldTypes.Number */:
                        fieldInput = new NumberField(field, defaultVal);
                        break;
                    case "Bool" /* FieldTypes.Boolean */:
                        fieldInput = new BooleanField(field, defaultVal);
                        break;
                    case "ControlPoint" /* FieldTypes.ControlPoint */:
                        fieldInput = new ControlPointField(field, defaultVal);
                        break;
                    case "Vector" /* FieldTypes.Vector */:
                        fieldInput = new VectorField(field, defaultVal);
                        break;
                    case "Color" /* FieldTypes.Color */:
                        fieldInput = new ColorField(field, defaultVal, this.scene);
                        break;
                    case "Texture" /* FieldTypes.Texture */:
                        fieldInput = new SpriteField(field, defaultVal, this.scene);
                        break;
                    case "FloatAttribute" /* FieldTypes.FloatAttribute */:
                    case "VectorAttribute" /* FieldTypes.VectorAttribute */:
                    case "RotationAttribute" /* FieldTypes.RotationAttribute */:
                    case "Orientation" /* FieldTypes.Orientation */:
                        fieldInput = new OptionField(field, valType, defaultVal);
                        break;
                    case "BlendType" /* FieldTypes.BlendType */:
                        fieldInput = new OptionField(field, valType, defaultVal);
                        break;
                    default:
                        fieldInput = new TextField(field, defaultVal);
                }
                args[name] = fieldInput.getValue();
                fieldInput.onChange((value) => {
                    args[name] = value;
                    this.onValueChanged(args);
                });
                fieldBoxName.addEventListener("contextmenu", (e) => {
                    fieldInput.reset();
                });
            }
            this.onValueChanged(args);
        }
        else {
            let classArgs = ParticlePropertiesTypes;
            this.properties = {};
            for (let [name, valType] of Object.entries(classArgs)) {
                let field = document.createElement("div");
                field.classList.add("FieldBox");
                this.baseWindow.appendChild(field);
                const fieldBoxName = document.createElement("div");
                fieldBoxName.classList.add("FieldBoxName");
                fieldBoxName.innerHTML = name;
                fieldBoxName.setAttribute("title", name);
                field.appendChild(fieldBoxName);
                let defaultVal = rawArgs[name];
                let fieldInput;
                switch (valType) {
                    case "Number" /* FieldTypes.Number */:
                        fieldInput = new NumberField(field, defaultVal);
                        break;
                    case "Bool" /* FieldTypes.Boolean */:
                        fieldInput = new BooleanField(field, defaultVal);
                        break;
                    case "ControlPoint" /* FieldTypes.ControlPoint */:
                        fieldInput = new ControlPointField(field, defaultVal);
                        break;
                    case "Vector" /* FieldTypes.Vector */:
                        fieldInput = new VectorField(field, defaultVal);
                        break;
                    case "Color" /* FieldTypes.Color */:
                        fieldInput = new ColorField(field, defaultVal, this.scene);
                        break;
                    case "Texture" /* FieldTypes.Texture */:
                        fieldInput = new SpriteField(field, defaultVal, this.scene);
                        break;
                    case "FloatAttribute" /* FieldTypes.FloatAttribute */:
                    case "VectorAttribute" /* FieldTypes.VectorAttribute */:
                    case "RotationAttribute" /* FieldTypes.RotationAttribute */:
                    case "Orientation" /* FieldTypes.Orientation */:
                        fieldInput = new OptionField(field, valType, defaultVal);
                        break;
                    case "BlendType" /* FieldTypes.BlendType */:
                        fieldInput = new OptionField(field, valType, defaultVal);
                        break;
                    default:
                        fieldInput = new TextField(field, defaultVal);
                }
                fieldInput.onChange((value) => {
                    this.onPropertyChanged(name, value);
                });
                this.properties[name] = fieldInput;
                fieldBoxName.addEventListener("contextmenu", (e) => {
                    fieldInput.reset();
                });
            }
        }
    }
    setProperty(name, rawVal) {
        if (this.properties) {
            if (this.properties[name])
                this.properties[name].setValue(rawVal);
        }
    }
    onPropertyChanged(name, value) {
        this.scene.setParticleProperty(name, value);
    }
    onValueChanged(args) {
        this.fId = this.scene.addParticleFunction(this.fId, this.name, args);
    }
    getID() {
        return this.fId;
    }
    disable(state, type) {
        this.scene.setParticleFunctionDisableState(state, type, this.fId);
    }
    show() {
        let details = this.controlPanel.getDetailsPanel();
        details.show(this);
        this.shown = true;
        this.baseWindow.classList.add("open");
    }
    remove() {
        if (this.shown) {
            let details = this.controlPanel.getDetailsPanel();
            details.hide();
        }
        this.scene.removeParticleFunction(this.fId);
        this.baseWindow.remove();
        delete allDetails[this.id];
    }
}
export class DetailsPanel {
    constructor(root) {
        this.small = false;
        this.baseDetails = document.createElement("div");
        this.baseDetails.setAttribute("id", "DetailsPanel");
        root.appendChild(this.baseDetails);
        this.baseDetails.classList.add("empty");
        this.baseDetails.style.height = "40px";
        let topBar = document.createElement("div");
        topBar.setAttribute("id", "DetailsTopBar");
        this.baseDetails.appendChild(topBar);
        let detailsTitle = document.createElement("div");
        detailsTitle.setAttribute("id", "DetailsTitle");
        topBar.appendChild(detailsTitle);
        detailsTitle.innerHTML = "Details";
        detailsTitle.classList.add("noSelect");
        this.detailsTitle = detailsTitle;
        this.showButton = document.createElement("img");
        this.showButton.setAttribute("id", "showIcon");
        this.showButton.src = "../res/arrow.svg";
        this.showButton.addEventListener("click", (e) => {
            if (this.small) {
                this.small = false;
                this.baseDetails.classList.remove("small");
                if (this.curDetails) {
                    this.curDetails.baseWindow.style.display = "block";
                    let height = 50 + this.curDetails.baseWindow.clientHeight;
                    this.baseDetails.style.height = height + "px";
                    setTimeout(() => {
                        if (this.curDetails)
                            this.curDetails.baseWindow.classList.remove("hidden");
                    }, 200);
                }
            }
            else {
                this.small = true;
                if (this.curDetails) {
                    this.curDetails.baseWindow.classList.add("hidden");
                    setTimeout(() => {
                        if (this.curDetails)
                            this.curDetails.baseWindow.style.display = "none";
                        this.baseDetails.classList.add("small");
                        this.baseDetails.style.height = "40px";
                    }, 100);
                }
                else {
                    this.baseDetails.classList.add("small");
                }
            }
        });
        this.sizeBar = document.createElement("div");
        this.sizeBar.setAttribute("id", "DetailsSizeBar");
        this.baseDetails.appendChild(this.sizeBar);
        let detailsWidth = 300;
        let resizing = false;
        let rootDoc = document.documentElement;
        this.sizeBar.addEventListener("mousedown", (e) => {
            resizing = true;
            rootDoc.style.userSelect = "none";
            rootDoc.addEventListener("mousemove", resizeControl);
            this.baseDetails.classList.add("resizing");
            let controlBase = this.baseDetails.parentElement.querySelector("#ControlPanel");
            controlBase.classList.add("resizing");
            let status = this.baseDetails.parentElement.querySelector("#ParticleStatus");
            status.classList.add("moving");
        });
        function resizeControl(e) {
            if (!resizing) {
                rootDoc.removeEventListener("mousemove", resizeControl);
                return;
            }
            detailsWidth += e.movementX;
            rootDoc.style.setProperty("--details-root-width", detailsWidth + "px");
        }
        rootDoc.addEventListener("mouseup", (e) => {
            resizing = false;
            rootDoc.style.userSelect = "text";
            this.baseDetails.classList.remove("resizing");
            let controlBase = this.baseDetails.parentElement.querySelector("#ControlPanel");
            controlBase.classList.remove("resizing");
            let status = this.baseDetails.parentElement.querySelector("#ParticleStatus");
            status.classList.remove("moving");
        });
    }
    show(details) {
        if (this.curDetails) {
            this.baseDetails.removeChild(this.curDetails.baseWindow);
            this.curDetails.shown = false;
            this.curDetails.baseWindow.classList.add("hidden");
        }
        this.curDetails = details;
        this.baseDetails.appendChild(this.curDetails.baseWindow);
        this.baseDetails.classList.remove("empty");
        this.curDetails.baseWindow.style.display = "block";
        let height = 50 + this.curDetails.baseWindow.clientHeight;
        this.baseDetails.style.height = height + "px";
        setTimeout(() => {
            if (this.curDetails)
                this.curDetails.baseWindow.classList.remove("hidden");
        }, 200);
    }
    hide() {
        if (this.curDetails) {
            this.baseDetails.removeChild(this.curDetails.baseWindow);
            this.curDetails.baseWindow.classList.add("hidden");
            this.curDetails.shown = false;
            this.curDetails = undefined;
        }
        this.baseDetails.classList.add("empty");
        this.baseDetails.style.height = "40px";
        this.small = false;
        this.baseDetails.classList.remove("small");
    }
    open() {
        this.baseDetails.classList.add("open");
        setTimeout(() => {
            this.detailsTitle.classList.add("open");
            if (this.curDetails)
                this.curDetails.baseWindow.classList.add("open");
        }, 400);
    }
    close() {
        this.baseDetails.classList.remove("open");
        this.detailsTitle.classList.remove("open");
        if (this.curDetails)
            this.curDetails.baseWindow.classList.remove("open");
    }
}
class DetailsField {
    reset() {
        this.setValue(this.origValue);
    }
}
class TextField extends DetailsField {
    constructor(parent, startVal) {
        super();
        this.element = document.createElement("input");
        parent.appendChild(this.element);
        this.element.classList.add("FieldInput");
        this.element.addEventListener("input", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.setValue(startVal);
        this.origValue = startVal;
    }
    getValue() {
        return "";
    }
    setValue(val) {
        this.element.value = val;
    }
    onChange(callback) {
        this.callback = callback;
    }
}
class NumberField extends DetailsField {
    constructor(parent, startVal) {
        super();
        this.element = document.createElement("input");
        parent.appendChild(this.element);
        this.element.classList.add("FieldInput");
        this.element.addEventListener("input", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.element.addEventListener("change", (e) => {
            this.element.value = this.getValue().toString();
        });
        this.setValue(startVal);
        this.origValue = startVal;
    }
    getValue() {
        return ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, this.element.value);
    }
    setValue(val) {
        this.element.value = val;
    }
    onChange(callback) {
        this.callback = callback;
    }
}
class ControlPointField extends NumberField {
    getValue() {
        return ParticleFunctions.parseVal("ControlPoint" /* FieldTypes.ControlPoint */, this.element.value);
    }
}
class BooleanField extends DetailsField {
    constructor(parent, startVal) {
        super();
        this.element = document.createElement("input");
        this.element.setAttribute("type", "checkbox");
        parent.appendChild(this.element);
        parent.classList.add("FieldCheckbox");
        this.element.addEventListener("change", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.element.checked = ParticleFunctions.parseVal("Bool" /* FieldTypes.Boolean */, startVal);
        this.origValue = startVal;
    }
    getValue() {
        return this.element.checked;
    }
    setValue(val) {
        this.element.value = val;
    }
    onChange(callback) {
        this.callback = callback;
    }
}
class VectorField extends DetailsField {
    constructor(parent, startVal) {
        super();
        this.element = document.createElement("div");
        parent.appendChild(this.element);
        this.element.classList.add("VectorInput");
        this.element1 = document.createElement("input");
        this.element.appendChild(this.element1);
        this.element1.addEventListener("input", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.element2 = document.createElement("input");
        this.element.appendChild(this.element2);
        this.element2.addEventListener("input", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.element3 = document.createElement("input");
        this.element.appendChild(this.element3);
        this.element3.addEventListener("input", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.setValue(startVal);
        this.origValue = startVal;
    }
    getValue() {
        const textVal = this.element1.value + " " + this.element2.value + " " + this.element3.value;
        return new Vector(textVal);
    }
    setValue(val) {
        let arr = val.split(" ");
        this.element1.value = arr[0] !== undefined ? arr[0] : "0";
        this.element2.value = arr[1] !== undefined ? arr[1] : "0";
        this.element3.value = arr[2] !== undefined ? arr[2] : "0";
    }
    onChange(callback) {
        this.callback = callback;
    }
}
class OptionField extends DetailsField {
    constructor(parent, type, startVal) {
        super();
        this.type = type;
        this.element = document.createElement("select");
        parent.appendChild(this.element);
        this.element.classList.add("FieldInput");
        let attrEnum;
        switch (this.type) {
            case "FloatAttribute" /* FieldTypes.FloatAttribute */:
                attrEnum = ParticleFloatAttributes;
                break;
            case "VectorAttribute" /* FieldTypes.VectorAttribute */:
                attrEnum = ParticleVectorAttributes;
                break;
            case "RotationAttribute" /* FieldTypes.RotationAttribute */:
                attrEnum = ParticleRotationAttributes;
                break;
            case "Orientation" /* FieldTypes.Orientation */:
                attrEnum = RendererOrientation;
                break;
            case "BlendType" /* FieldTypes.BlendType */:
                attrEnum = RendererBlendType;
                break;
        }
        for (const val of Object.values(attrEnum)) {
            const option = document.createElement("option");
            option.value = val;
            option.text = val;
            this.element.appendChild(option);
        }
        this.element.addEventListener("input", (e) => {
            if (this.callback)
                this.callback(this.getValue());
        });
        this.setValue(startVal);
        this.origValue = startVal;
    }
    getValue() {
        return ParticleFunctions.parseVal(this.type, this.element.value);
    }
    setValue(val) {
        this.element.value = val.toString();
    }
    onChange(callback) {
        this.callback = callback;
    }
}
class ColorField extends DetailsField {
    constructor(parent, startVal, scene) {
        super();
        this.element = document.createElement("input");
        this.element.type = "submit";
        parent.appendChild(this.element);
        this.element.classList.add("ColorInput");
        this.curColor = new Color(startVal);
        this.element.addEventListener("click", () => {
            openColorPopup(scene, this.curColor, (newColor) => {
                this.curColor = newColor;
                this.element.style.backgroundColor = this.curColor.exportHTML();
                if (this.callback)
                    this.callback(this.getValue());
            });
        });
        this.setValue(startVal);
        this.origValue = startVal;
    }
    getValue() {
        return this.curColor;
    }
    setValue(val) {
        this.curColor = new Color(val);
        this.element.style.backgroundColor = this.curColor.exportHTML();
    }
    onChange(callback) {
        this.callback = callback;
    }
}
class SpriteField extends DetailsField {
    constructor(parent, startVal, scene) {
        super();
        this.path = startVal;
        parent.classList.add("BigField");
        this.box = document.createElement("div");
        parent.appendChild(this.box);
        this.box.classList.add("SpriteField");
        this.imageElement = document.createElement("img");
        this.box.appendChild(this.imageElement);
        this.imageElement.classList.add("FieldImage");
        if (startVal === "DEFAULT_SPRITE") {
            this.imageElement.src = "res/particles/base_sprite.png";
        }
        else {
            this.imageElement.src = startVal;
        }
        this.element = document.createElement("input");
        this.box.appendChild(this.element);
        this.element.classList.add("FieldInput");
        this.element.disabled = true;
        this.setValue(this.getNameFromPath(startVal));
        this.origValue = startVal;
        this.imageElement.addEventListener("click", () => {
            openSpritePopup(scene, this.path, (name, path) => {
                if (name === path) {
                    this.setValue(this.getNameFromPath(startVal));
                }
                else {
                    this.setValue(name);
                }
                if (path === "DEFAULT_SPRITE") {
                    this.imageElement.src = "res/particles/base_sprite.png";
                }
                else {
                    this.imageElement.src = path;
                }
                this.path = path;
                if (this.callback)
                    this.callback(this.getValue());
            });
        });
    }
    getNameFromPath(path) {
        let nameMatch = path.match(/(?:.*\\)?(.*).png/);
        if (nameMatch) {
            return nameMatch[1];
        }
        return path;
    }
    getValue() {
        return this.path;
    }
    setValue(val) {
        this.element.value = val;
    }
    onChange(callback) {
        this.callback = callback;
    }
}
