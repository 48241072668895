import { ParticleScene } from "./particle/particle_scene";
import { Wordle } from "./projects/wordle";
var fileName = location.pathname;
switch (fileName) {
    case "/index.html":
        let body = document.body;
        let element = body.querySelector("#sword_anim");
        // InitScene(element);
        // AddSword();
        break;
    case "/dota.html":
        // const rootElement = document.createElement("div");
        // rootElement.setAttribute("id", "ParticleTestFrame");
        // document.body.appendChild(rootElement);
        // const frame = new ParticleFrame(rootElement);
        // frame.loadJsonPath("http://127.0.0.1:5500/res/prebuild/stars.json");
        // frame.zoom(15);
        const scene = new ParticleScene({
            isEditor: true,
        });
        scene.loadTestParticle();
        // scene.loadJsonPath("http://127.0.0.1:5500/res/prebuild/stars.json");
        break;
    case "/projects/wordle.html":
        new Wordle();
        break;
}
function toggleHamburger() {
    const hamburger = document.getElementsByClassName("nav-hamburger")[0];
    hamburger.classList.toggle("open");
    const navItems = document.getElementsByClassName("nav-item");
    for (const item of navItems) {
        item.classList.toggle("open");
    }
}
//@ts-ignore
window.toggleHamburger = toggleHamburger;
function test(arg) {
    return new Promise((resolve, reject) => {
        if (arg === "a") {
            resolve(1);
        }
        else {
            reject("1");
        }
    });
}
const myPromise = test("a");
myPromise.then((value) => { }); //value is of type `number`
myPromise.catch((reason) => { }); //reason is of type `string`
