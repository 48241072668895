import { ParticleEmitter, ParticleForces, ParticleInitializer, ParticleOperator, } from "./particles_operators";
import { ParticleRenderer } from "./particle_renderer";
import { Color, Vector, isNumeric } from "./util";
/**
 * Collection of utility functions for particle functions
 */
export var ParticleFunctions;
(function (ParticleFunctions) {
    /**
     * Get the particle collection according to a function type
     * @param type function type
     * @returns particle collection
     */
    function getParticleCollection(type) {
        let funcs;
        switch (type) {
            case "Operator" /* FunctionType.Operator */:
                funcs = ParticleOperator;
                break;
            case "Emitter" /* FunctionType.Emitter */:
                funcs = ParticleEmitter;
                break;
            case "Initializer" /* FunctionType.Initializer */:
                funcs = ParticleInitializer;
                break;
            case "Renderer" /* FunctionType.Renderer */:
                funcs = ParticleRenderer;
                break;
            case "Force" /* FunctionType.Force */:
                funcs = ParticleForces;
                break;
        }
        return funcs;
    }
    ParticleFunctions.getParticleCollection = getParticleCollection;
    /**
     * Gets the class arguments (type + default value) for a specific function
     * @param type function type
     * @param name name of the function
     * @returns class arguments
     */
    function getFunctionArgs(type, name) {
        let value;
        const funcs = getParticleCollection(type);
        for (const func of Object.values(funcs)) {
            if (func.name === name)
                value = func.getArgs();
        }
        return value;
    }
    ParticleFunctions.getFunctionArgs = getFunctionArgs;
    /**
     * Gets a list of all functions of a specific function type
     * @param type function type
     * @returns list of functions
     */
    function getNamesForFunctionType(type) {
        const values = [];
        const funcs = getParticleCollection(type);
        for (const func of Object.values(funcs)) {
            values.push(func.name);
        }
        return values;
    }
    ParticleFunctions.getNamesForFunctionType = getNamesForFunctionType;
    /**
     * Parses a field value (often string) to its proper type
     * @param type field type
     * @param val field value
     * @returns parsed value
     */
    function parseVal(type, val) {
        switch (type) {
            case "Number" /* FieldTypes.Number */:
                if (!isNumeric(val))
                    return 0;
                return parseFloat(val);
            case "ControlPoint" /* FieldTypes.ControlPoint */:
                if (!isNumeric(val))
                    return 0;
                let num = parseFloat(val);
                return Math.min(50, Math.max(0, num));
            case "Bool" /* FieldTypes.Boolean */:
                if (typeof val === "boolean")
                    return val;
                if (val === "1")
                    return true;
                if (val === "true")
                    return true;
                return false;
            case "Vector" /* FieldTypes.Vector */:
                return new Vector(val);
            case "Color" /* FieldTypes.Color */:
                return new Color(val);
            default:
                return val;
        }
    }
    ParticleFunctions.parseVal = parseVal;
    /**
     * Parses a function info with raw string args to properly typed args
     * @param type function type
     * @param info function info
     * @returns parsed args
     */
    function parseRawInfo(type, info) {
        const classArgs = getFunctionArgs(type, info.name);
        if (!classArgs)
            return;
        const newArgs = {};
        for (let [name, value] of Object.entries(info.args ?? {})) {
            newArgs[name] = parseVal(classArgs[name][0], value);
        }
        return newArgs;
    }
    ParticleFunctions.parseRawInfo = parseRawInfo;
    /**
     * Parses all information of a list of particle functions to a string
     * @param type function type
     * @param arr list of particle functions
     * @returns combined string
     */
    function getUrlStringData(type, arr) {
        let funcs = [];
        arr.forEach((func) => {
            const classArgs = getFunctionArgs(type, func.constructor.name);
            let disabled = func.disabled ? "!" : "";
            let classString = func.constructor.name + disabled + "(";
            let options = [];
            for (const [name, val] of Object.entries(func.getOptions())) {
                if (classArgs && val == classArgs[name][1])
                    continue; // ignore value if its the default!
                if (typeof val !== "boolean") {
                    options.push(name + ":" + val.toString());
                }
                else {
                    options.push(name + ":" + (val ? "1" : "0"));
                }
            }
            classString += options.join("|") + ")";
            funcs.push(classString);
        });
        return "{" + funcs.join(";") + "}";
    }
    ParticleFunctions.getUrlStringData = getUrlStringData;
    /**
     * Parses particle info strings from the url to a json object
     * @param data formatted particle info string (from url)
     * @returns json object
     */
    function getFuncFromUrlString(data) {
        let match;
        const re = /{?(.+?)}?(;|$)/g;
        const funcs = [];
        while ((match = re.exec(data))) {
            const nameMatch = match[1].match(/(\w+)(!)?\((.*)\)/);
            if (nameMatch) {
                const name = nameMatch[1];
                const args = {};
                const argRe = /(\w+):([\w. \-+\\]+)/g;
                let argMatch;
                const disabled = nameMatch[2] !== undefined;
                while ((argMatch = argRe.exec(nameMatch[3]))) {
                    args[argMatch[1]] = argMatch[2];
                }
                const func = {
                    name,
                };
                if (Object.keys(args).length > 0)
                    func.args = args;
                if (disabled)
                    func.disabled = true;
                funcs.push(func);
            }
        }
        return funcs;
    }
    ParticleFunctions.getFuncFromUrlString = getFuncFromUrlString;
})(ParticleFunctions || (ParticleFunctions = {}));
