import { DetailsPanel, FunctionButton } from "./ui_classes";
export class ControlPanel {
    constructor(rootElement, scene) {
        this.groups = {};
        this.latestFuncButton = {};
        this.lock = false;
        this.rootElement = rootElement;
        this.scene = scene;
        let controlPanelBase = document.createElement("div");
        controlPanelBase.setAttribute("id", "ControlPanel");
        rootElement.appendChild(controlPanelBase);
        this.controlBase = controlPanelBase;
        let controlSizeBar = document.createElement("div");
        controlSizeBar.setAttribute("id", "ControlSizeBar");
        this.controlBase.appendChild(controlSizeBar);
        let controlWidth = 400;
        let resizing = false;
        let root = document.documentElement;
        controlSizeBar.addEventListener("mousedown", (e) => {
            resizing = true;
            root.style.userSelect = "none";
            root.addEventListener("mousemove", resizeControl);
            controlPanelBase.classList.add("resizing");
            let status = this.controlBase.parentElement.querySelector("#ParticleStatus");
            status.classList.add("moving");
        });
        function resizeControl(e) {
            if (!resizing) {
                root.removeEventListener("mousemove", resizeControl);
                return;
            }
            controlWidth += e.movementX;
            root.style.setProperty("--control-root-width", controlWidth + "px");
        }
        root.addEventListener("mouseup", (e) => {
            resizing = false;
            root.style.userSelect = "text";
            controlPanelBase.classList.remove("resizing");
            let status = this.controlBase.parentElement.querySelector("#ParticleStatus");
            status.classList.remove("moving");
        });
        let controlPanelTextHidden = document.createElement("div");
        controlPanelTextHidden.setAttribute("id", "ControlPanelTextHidden");
        controlPanelBase.appendChild(controlPanelTextHidden);
        controlPanelBase.addEventListener("click", () => {
            this.open();
        });
        let controlPanelTextHiddenLabel = document.createElement("div");
        controlPanelTextHidden.appendChild(controlPanelTextHiddenLabel);
        controlPanelTextHiddenLabel.innerHTML = "Particle Control";
        let controlPanelShown = document.createElement("div");
        controlPanelShown.setAttribute("id", "ControlPanelTextShown");
        controlPanelBase.appendChild(controlPanelShown);
        let title = document.createElement("div");
        title.setAttribute("id", "ControlTitle");
        controlPanelShown.appendChild(title);
        title.innerHTML = "Particle Control";
        title.classList.add("noSelect");
        let controlClose = document.createElement("img");
        controlClose.setAttribute("id", "ControlClose");
        controlClose.src = "../res/cancel.svg";
        controlClose.addEventListener("click", () => {
            this.close();
        });
        controlPanelShown.appendChild(controlClose);
        let description = document.createElement("div");
        description.setAttribute("id", "ControlDescription");
        controlPanelShown.appendChild(description);
        description.innerHTML =
            "Use the + icons below to add new functions to the groups and 'Enter' to confirm";
        this.basePropertyPanel = document.createElement("div");
        this.basePropertyPanel.setAttribute("id", "ControlBase");
        this.basePropertyPanel.classList.add("FunctionGroup");
        controlPanelShown.appendChild(this.basePropertyPanel);
        let groupTitle = document.createElement("div");
        groupTitle.classList.add("FunctionGroupTitle");
        groupTitle.innerHTML = "Base Properties";
        this.basePropertyPanel.appendChild(groupTitle);
        this.addFunctionGroup(controlPanelShown, "Emitter" /* FunctionType.Emitter */);
        this.addFunctionGroup(controlPanelShown, "Initializer" /* FunctionType.Initializer */);
        this.addFunctionGroup(controlPanelShown, "Operator" /* FunctionType.Operator */);
        this.addFunctionGroup(controlPanelShown, "Force" /* FunctionType.Force */);
        this.addFunctionGroup(controlPanelShown, "Renderer" /* FunctionType.Renderer */);
        this.baseDetails = new DetailsPanel(rootElement);
    }
    forceOpenControl() {
        if (!this.controlBase.classList.contains("open")) {
            this.open();
        }
    }
    forceCloseControl() {
        if (this.controlBase.classList.contains("open")) {
            this.close();
        }
    }
    getDetailsPanel() {
        return this.baseDetails;
    }
    addBaseProperties(rawArgs) {
        this.baseProperties = new FunctionButton(this.scene, this, this.basePropertyPanel, "Base", rawArgs, false);
    }
    setPropertyValue(name, val) {
        if (!this.baseProperties) {
            const currentSystem = this.scene.getRootSystem();
            if (!currentSystem)
                return;
            currentSystem.setupDefaultValues();
        }
        if (this.baseProperties)
            this.baseProperties.setProperty(name, val);
    }
    addFunctionGroup(parent, name) {
        let group = document.createElement("div");
        group.setAttribute("id", "Control" + name);
        group.classList.add("FunctionGroup");
        parent.appendChild(group);
        let groupTitle = document.createElement("div");
        groupTitle.classList.add("FunctionGroupTitle");
        groupTitle.innerHTML = name;
        group.appendChild(groupTitle);
        let fButton = new FunctionButton(this.scene, this, group);
        fButton.setGroupName(name);
        this.groups[name] = group;
        this.latestFuncButton[name] = fButton;
    }
    addEmptyFuntion(name) {
        let group = this.groups[name];
        if (group) {
            let fButton = new FunctionButton(this.scene, this, group);
            fButton.setGroupName(name);
            this.latestFuncButton[name] = fButton;
        }
    }
    loadFunction(type, name, rawArgs, disabled) {
        let group = this.groups[type];
        if (group) {
            let fButton = this.latestFuncButton[type];
            if (!fButton) {
                fButton = new FunctionButton(this.scene, this, group);
                fButton.setGroupName(type);
            }
            fButton.loadFunction(name, rawArgs);
            fButton.setDisabledState(disabled);
        }
    }
    open() {
        if (this.lock)
            return;
        const child = this.controlBase.querySelector("#ControlPanelTextShown");
        const status = this.controlBase.parentElement.querySelector("#ParticleStatus");
        if (!this.controlBase.classList.contains("open")) {
            this.controlBase.classList.add("open");
            status.classList.add("open");
            this.baseDetails.open();
            setTimeout(() => {
                child.style.display = "flex";
            }, 200);
            setTimeout(() => {
                child.style["opacity"] = "1";
            }, 400);
        }
    }
    close() {
        this.lock = true;
        let child = this.controlBase.querySelector("#ControlPanelTextShown");
        let status = this.controlBase.parentElement.querySelector("#ParticleStatus");
        if (this.controlBase.classList.contains("open")) {
            this.controlBase.classList.remove("open");
            status.classList.remove("open");
            child.style["opacity"] = "0";
            this.baseDetails.close();
            setTimeout(() => {
                child.style.display = "none";
            }, 200);
        }
        setTimeout(() => {
            this.lock = false;
        }, 200);
    }
}
