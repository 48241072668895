// export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
const URL = "http://127.0.0.1:5500";
export class Vector {
    constructor(x, y, z) {
        if (typeof x === "number") {
            this.x = x;
            this.y = y !== undefined ? y : x;
            this.z = z !== undefined ? z : x;
        }
        else if (typeof x === "string") {
            let arr = x.split(" ");
            this.x = parseFloat(arr[0]);
            this.x = isNaN(this.x) ? 0 : this.x;
            this.y = parseFloat(arr[1]);
            this.y = isNaN(this.y) ? 0 : this.y;
            this.z = parseFloat(arr[2]);
            this.z = isNaN(this.z) ? 0 : this.z;
        }
        else {
            this.x = 0;
            this.y = 0;
            this.z = 0;
        }
    }
    static fromThree(vec) {
        return new Vector(vec.x, vec.y, vec.z);
    }
    reset() {
        this.x = 0;
        this.y = 0;
        this.z = 0;
    }
    add(vec) {
        return new Vector(this.x + vec.x, this.y + vec.y, this.z + vec.z);
    }
    addSelf(vec) {
        this.x += vec.x;
        this.y += vec.y;
        this.z += vec.z;
    }
    sub(vec) {
        return new Vector(this.x - vec.x, this.y - vec.y, this.z - vec.z);
    }
    mult(val) {
        return new Vector(this.x * val, this.y * val, this.z * val);
    }
    multSelf(val) {
        this.x *= val;
        this.y *= val;
        this.z *= val;
    }
    div(val) {
        return new Vector(this.x / val, this.y / val, this.z / val);
    }
    length() {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2) + Math.pow(this.z, 2));
    }
    normalized() {
        const length = this.length();
        if (length == 0)
            return this.clone();
        return new Vector(this.x / length, this.y / length, this.z / length);
    }
    normalize() {
        const length = this.length();
        if (length == 0)
            return;
        this.x = this.x / length;
        this.y = this.y / length;
        this.z = this.z / length;
    }
    negated() {
        return new Vector(-this.x, -this.y, -this.z);
    }
    negate() {
        this.x = -this.x;
        this.y = -this.y;
        this.z = -this.z;
    }
    directionTo(vec) {
        return this.sub(vec).normalized();
    }
    distanceTo(vec) {
        return this.sub(vec).length();
    }
    equals(vec) {
        return this.x === vec.x && this.y === vec.y && this.z === vec.z;
    }
    clone() {
        return new Vector(this.x, this.y, this.z);
    }
    exportThree() {
        return new THREE.Vector3(this.x, this.y, this.z);
    }
    toString() {
        return this.x + " " + this.y + " " + this.z;
    }
}
export class Orientation {
    constructor(xRot, yRot, zRot) {
        this.xRot = xRot;
        this.yRot = yRot;
        this.zRot = zRot;
    }
}
/**
 * Color class to manage rgb colro objects. Can convert to other color standarts as well.
 */
export class Color {
    constructor(r, g, b, a) {
        if (typeof r === "number") {
            this.r = Math.round(r);
            this.g = g !== undefined ? Math.round(g) : r;
            this.b = b !== undefined ? Math.round(b) : r;
        }
        else if (typeof r === "string") {
            let arr = r.split(" ");
            this.r = parseInt(arr[0], 10);
            this.r = isNaN(this.r) ? 0 : this.r;
            this.g = parseInt(arr[1], 10);
            this.g = isNaN(this.g) ? 0 : this.g;
            this.b = parseInt(arr[2], 10);
            this.b = isNaN(this.b) ? 0 : this.b;
        }
        else {
            this.r = 255;
            this.g = 255;
            this.b = 255;
        }
    }
    static fromHSV(h, s, v) {
        let f = (n, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
        let rgb = [f(5), f(3), f(1)];
        return new Color(rgb[0] * 255, rgb[1] * 255, rgb[2] * 255);
    }
    toString() {
        return this.r + " " + this.g + " " + this.b;
    }
    export() {
        return new THREE.Color(this.exportHTML());
    }
    exportHTML(a) {
        if (a)
            return "rgba(" + this.r + ", " + this.g + ", " + this.b + ", " + a + ")";
        return "rgb(" + this.r + ", " + this.g + ", " + this.b + ")";
    }
    exportRaw() {
        return [this.r / 255, this.g / 255, this.b / 255];
    }
    exportHSV() {
        function rgb2hsv(r, g, b) {
            let v = Math.max(r, g, b), c = v - Math.min(r, g, b);
            let h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);
            return [60 * (h < 0 ? h + 6 : h), v && c / v, v];
        }
        let hsv = rgb2hsv(this.r / 255, this.g / 255, this.b / 255);
        return { h: hsv[0], s: hsv[1], v: hsv[2] };
    }
    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    exportHex() {
        return ("#" +
            this.componentToHex(this.r) +
            this.componentToHex(this.g) +
            this.componentToHex(this.b));
    }
}
export var EndCapState;
(function (EndCapState) {
    EndCapState[EndCapState["ALWAYS"] = 0] = "ALWAYS";
    EndCapState[EndCapState["ONLY_ENDCAP"] = 1] = "ONLY_ENDCAP";
    EndCapState[EndCapState["NO_ENDCAP"] = 2] = "NO_ENDCAP";
})(EndCapState || (EndCapState = {}));
export function randomNumber(min, max) {
    if (min === max)
        return min;
    return min + Math.random() * (max - min);
}
export function compareEndcap(isEndcap, state) {
    if (state === EndCapState.ALWAYS)
        return true;
    if (isEndcap && state === EndCapState.ONLY_ENDCAP)
        return true;
    if (!isEndcap && state === EndCapState.NO_ENDCAP)
        return true;
    return false;
}
let uniqueIDs = [];
export function generateUniqueID() {
    let unique = false;
    let id = 0;
    while (!unique) {
        id = new Date().getUTCMilliseconds();
        if (!uniqueIDs.includes(id)) {
            uniqueIDs.push(id);
            unique = true;
        }
    }
    return id;
}
export function getTextureFromName(name) {
    let path = "";
    if (name === "DEFAULT_SPRITE") {
        path = "https://github.nibuja-dev.xyz/res/particles/basic_glow.png";
    }
    else {
        path = name;
    }
    if (path === "")
        return;
    const map = new THREE.TextureLoader().load(path);
    return map;
}
export function getModelFromName(name) {
    return new Promise((resolve, reject) => {
        let path = name === "DEFAULT_MODEL"
            ? "https://github.nibuja-dev.xyz/res/big_sword.glb"
            : `"https://github.nibuja-dev.xyz/res/${name}.glb`;
        const loader = new GLTFLoader();
        loader.load(path, (gltf) => {
            gltf.scene.traverse((child) => {
                let mesh = child;
                if (mesh.isMesh)
                    resolve(mesh);
            });
        }, undefined, (error) => {
            console.error(error);
            reject(error);
        });
    });
}
export function getNameFromTexture(texture) {
    if (!texture)
        return "";
    return texture.name;
}
export function clearChildren(element) {
    while (element.lastElementChild) {
        element.lastElementChild.remove();
    }
}
// strength indicated the "linear part" of the ease: strengt of 1 means no linear part at all;
export function interpolate(startVal, endVal, perc, type) {
    const linear = function () {
        return startVal + (endVal - startVal) * perc;
    };
    if (!type ||
        (type && type.type === "Linear" /* InterpolationType.Linear */) ||
        (type && type.strength === 0)) {
        return linear();
    }
    let mult = type.strength ?? 1;
    if (type.type === "EaseInQuad" /* InterpolationType.EaseInQuad */) {
        const func = function () {
            return (endVal - startVal) * Math.pow(perc, 2) + startVal;
        };
        return (1 - mult) * linear() + mult * func();
    }
    if (type.type === "EaseOutQuad" /* InterpolationType.EaseOutQuad */) {
        const func = function () {
            return -(endVal - startVal) * perc * (perc - 2) + startVal;
        };
        return (1 - mult) * linear() + mult * func();
    }
    if (type.type === "EaseOutQuart" /* InterpolationType.EaseOutQuart */) {
        const l = linear();
        perc = perc - 1;
        endVal -= startVal;
        const func = function () {
            return -endVal * (perc * perc * perc * perc - 1) + startVal;
        };
        return (1 - mult) * l + mult * func();
    }
    if (type.type === "EaseInOut" /* InterpolationType.EaseInOut */) {
        const func = function () {
            if (perc / 2 < 1)
                return endVal * Math.pow(perc, 2) + startVal;
            return -endVal * Math.pow(perc, 2) + startVal;
        };
        return (1 - mult) * linear() + mult * func();
    }
    return startVal;
}
export function interpolateColors(startColor, endColor, perc) {
    if (startColor === endColor)
        return startColor;
    let newColor = new Color(interpolate(startColor.r, endColor.r, perc), interpolate(startColor.g, endColor.g, perc), interpolate(startColor.b, endColor.b, perc));
    return newColor;
}
export function interpolateVectors(startVec, endVec, perc) {
    if (startVec === endVec)
        return startVec;
    let newVec = new Vector(interpolate(startVec.x, endVec.x, perc), interpolate(startVec.y, endVec.y, perc), interpolate(startVec.z, endVec.z, perc));
    return newVec;
}
export function ShortenNumber(num, decimal) {
    return Number(num.toFixed(decimal ?? 2));
}
export function isNumeric(str) {
    return !isNaN(parseFloat(str));
}
export function randomSign(x) {
    return x * (Math.random() < 0.5 ? -1 : 1);
}
const shaderFiles = [
    "basicSprite.vertex",
    "basicSprite.fragment",
    "animatedSprite.fragment",
];
let shader = new Map();
let shaderLoaded = false;
export function shaderLoadedSuccessfully() {
    return shaderLoaded;
}
export async function preLoadShader() {
    console.log("Fetching shader");
    for (const file of shaderFiles) {
        try {
            const response = await fetch(`${URL}/res/shader/${file}`);
            shader.set(file, await response.text());
        }
        catch {
            throw Error(`Could not load shader "${file}"`);
        }
    }
    shaderLoaded = true;
    return true;
}
export function getShader(name) {
    if (!shader.has(name))
        throw Error(`Tried to load shader "${name}". Not yet loaded!`);
    return shader.get(name);
}
export function downloadJson(content, name) {
    const temp = document.createElement("a");
    temp.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(content));
    temp.setAttribute("download", `${name}.json`);
    temp.style.display = "none";
    document.body.appendChild(temp);
    temp.click();
    document.body.removeChild(temp);
}
export async function loadFile(path) {
    try {
        return (await fetch(path)).text();
    }
    catch {
        throw Error(`Could not load file from "${path}"`);
    }
}
