import { Color, Vector } from "./util";
import * as _ from "lodash";
import { ParticleFunctions } from "./particle_function_util";
export class ParticleSystem {
    constructor(scene, name, controlPoints, root) {
        this.isRoot = false;
        this.shouldStart = false;
        this.paused = false;
        this.maxCount = 100;
        this.emitter = [];
        this.initializer = [];
        this.operator = [];
        this.renderer = [];
        this.forces = [];
        this.childs = [];
        this._particles = [];
        this.maxID = 0;
        this.zeroTicks = 0;
        this.setup = false;
        this.defaultValues = {
            lifeTime: 2,
            radius: 1,
            alpha: 255,
            roll: 0,
            pitch: 0,
            yaw: 0,
            trailLength: 1,
            velocity: new Vector(0, 0, 0),
            position: new Vector(0, 0, 0),
            color: new Color(),
            sequence: 0,
        };
        this.name = name;
        this.scene = scene;
        this.controlPoints = controlPoints;
        if (root) {
            this.isRoot = root;
            scene.setParticleName(name);
        }
    }
    get particles() {
        return this._particles;
    }
    start() {
        if (!this.shouldStart)
            return;
        this.setupDefaultValues();
        this.emitter.forEach((emitter) => {
            let count = emitter.onStart();
            if (count > 0)
                this.createParticles(count);
        });
        this.renderer.forEach((renderer) => {
            renderer.render(this._particles, this.scene.getRenderScene(), this.scene.getCamera());
        });
    }
    restart() {
        this.paused = false;
        let part = [...this._particles];
        part.forEach((particle) => {
            this.deleteParticle(particle);
        });
        this.setMaxID(0);
        this.start();
    }
    pause() {
        this.paused = true;
    }
    continue() {
        this.paused = false;
    }
    tick(delta) {
        if (this.paused)
            return;
        if (this.paused) {
            this.renderer.forEach((renderer) => {
                renderer.render(this._particles, this.scene.getRenderScene(), this.scene.getCamera());
            });
            return;
        }
        this.emitter.forEach((emitter) => {
            let count = emitter.onTick(delta, this._particles.length);
            if (count > 0)
                this.createParticles(count);
        });
        this._particles.forEach((particle) => {
            particle.tick(delta);
        });
        this.forces.forEach((force) => {
            force.onTick(delta, this._particles);
        });
        this.operator.forEach((operator) => {
            operator.onTick(delta, this._particles);
        });
        this.renderer.forEach((renderer) => {
            renderer.render(this._particles, this.scene.getRenderScene(), this.scene.getCamera());
        });
        // clean up!
        let deleteList = [];
        this._particles.forEach((particle) => {
            if (!particle.isAlive()) {
                deleteList.push(particle);
            }
        });
        deleteList.forEach((particle) => {
            this.deleteParticle(particle);
        });
        if (this.maxID === 0) {
            this.zeroTicks++;
        }
        if (this.zeroTicks > 250) {
            this.zeroTicks = 0;
            this.restart();
        }
    }
    setMaxID(id) {
        this.maxID = id;
        if (this.isRoot && this.scene.isEditor) {
            this.scene.setParticleCount(this.maxID, this.maxCount);
        }
    }
    createParticles(count) {
        for (let index = 1; index <= count; index++) {
            if (this.maxID < this.maxCount) {
                let newParticle = new Particle(this.maxID, this.getStartProperties());
                this.setMaxID(this.maxID + 1);
                this.initializer.forEach((initializer) => {
                    newParticle = initializer.onStart(newParticle);
                });
                this._particles.push(newParticle);
            }
            else
                return false;
        }
        return true;
    }
    deleteParticle(particle) {
        const id = particle.id;
        if (particle.object) {
            this.scene.getRenderScene().remove(particle.object);
        }
        let newParticles = this._particles.slice(0, id);
        for (let index = id; index < this._particles.length - 1; index++) {
            let particle = this._particles[index + 1];
            particle.id = index;
            newParticles.push(particle);
        }
        this._particles = newParticles;
        this.maxID -= 1;
    }
    getStartProperties() {
        return this.defaultValues;
    }
    addFunction(func, disable) {
        if (func.isEmitter())
            this.emitter.push(func);
        if (func.isInitializer())
            this.initializer.push(func);
        if (func.isOperator())
            this.operator.push(func);
        if (func.isRenderer())
            this.renderer.push(func);
        if (func.isForce())
            this.forces.push(func);
        if (disable)
            func.disabled = true;
        func.system = this;
        func.onInit();
        if (this.scene.isEditor)
            this.scene.saveSceneToUrl(this);
        return func.id;
    }
    removeFunction(func) {
        let isDisabled = false;
        if (func.isInitializer()) {
            let newArr = [];
            this.initializer.forEach((initializer) => {
                if (initializer.id !== func.id) {
                    newArr.push(initializer);
                }
                else {
                    isDisabled = initializer.disabled;
                    initializer.onDestroy();
                }
            });
            this.initializer = newArr;
        }
        if (func.isOperator()) {
            let newArr = [];
            this.operator.forEach((operator) => {
                if (operator.id !== func.id) {
                    newArr.push(operator);
                }
                else {
                    isDisabled = operator.disabled;
                    operator.onDestroy();
                }
            });
            this.operator = newArr;
        }
        if (func.isEmitter()) {
            let newArr = [];
            this.emitter.forEach((emitter) => {
                if (emitter.id !== func.id) {
                    newArr.push(emitter);
                }
                else {
                    isDisabled = emitter.disabled;
                    emitter.onDestroy();
                }
            });
            this.emitter = newArr;
        }
        if (func.isRenderer()) {
            let newArr = [];
            this.renderer.forEach((renderer) => {
                if (renderer.id !== func.id) {
                    newArr.push(renderer);
                }
                else {
                    isDisabled = renderer.disabled;
                    renderer.onDestroy();
                }
            });
            this.renderer = newArr;
        }
        if (func.isForce()) {
            let newArr = [];
            this.forces.forEach((force) => {
                if (force.id !== func.id) {
                    newArr.push(force);
                }
                else {
                    isDisabled = force.disabled;
                    force.onDestroy();
                }
            });
            this.forces = newArr;
        }
        if (this.scene.isEditor)
            this.scene.saveSceneToUrl(this);
        return isDisabled;
    }
    getEmitter() {
        return ParticleFunctions.getUrlStringData("Emitter" /* FunctionType.Emitter */, this.emitter);
    }
    getRenderer() {
        return ParticleFunctions.getUrlStringData("Renderer" /* FunctionType.Renderer */, this.renderer);
    }
    getInitializer() {
        return ParticleFunctions.getUrlStringData("Initializer" /* FunctionType.Initializer */, this.initializer);
    }
    getOperator() {
        return ParticleFunctions.getUrlStringData("Operator" /* FunctionType.Operator */, this.operator);
    }
    getForces() {
        return ParticleFunctions.getUrlStringData("Force" /* FunctionType.Force */, this.forces);
    }
    getProperties() {
        let props = [];
        for (const [name, val] of Object.entries(this.defaultValues)) {
            props.push(name + ":" + val);
        }
        return "{" + props.join(";") + "}";
    }
    setupDefaultValues() {
        if (this.setup)
            return;
        this.setup = true;
        if (this.scene.isEditor) {
            let args = {};
            for (const [name, val] of Object.entries(this.defaultValues)) {
                args[name] = val.toString();
            }
            const controlPanel = this.scene.getControlPanel();
            if (controlPanel)
                controlPanel.addBaseProperties(args);
        }
    }
    setBaseProperty(name, val) {
        this.defaultValues[name] = val;
        if (this.scene.isEditor)
            this.scene.saveSceneToUrl(this);
    }
    setFunctionDisableState(disable, type, id) {
        if (type === "Emitter" /* FunctionType.Emitter */) {
            this.emitter.forEach((emitter) => {
                if (id === emitter.id)
                    emitter.disabled = disable;
            });
        }
        else if (type === "Initializer" /* FunctionType.Initializer */) {
            this.initializer.forEach((initializer) => {
                if (id === initializer.id)
                    initializer.disabled = disable;
            });
        }
        else if (type === "Operator" /* FunctionType.Operator */) {
            this.operator.forEach((operator) => {
                if (id === operator.id)
                    operator.disabled = disable;
            });
        }
        else if (type === "Renderer" /* FunctionType.Renderer */) {
            this.renderer.forEach((renderer) => {
                if (id === renderer.id)
                    renderer.disabled = disable;
            });
        }
        else if (type === "Force" /* FunctionType.Force */) {
            this.forces.forEach((force) => {
                if (id === force.id)
                    force.disabled = disable;
            });
        }
        if (this.scene.isEditor)
            this.scene.saveSceneToUrl(this);
    }
    updateOrder(type, newOrder) {
        const newList = [];
        let oldList = this.getFunctionList(type);
        for (const id of newOrder) {
            const func = oldList.find((elem) => elem.id === id);
            if (func)
                newList.push(func);
        }
        this.setFunctionList(type, newList);
        if (this.scene.isEditor)
            this.scene.saveSceneToUrl(this);
    }
    getFunctionList(type) {
        switch (type) {
            case "Emitter" /* FunctionType.Emitter */:
                return this.emitter;
            case "Initializer" /* FunctionType.Initializer */:
                return this.initializer;
            case "Operator" /* FunctionType.Operator */:
                return this.operator;
            case "Renderer" /* FunctionType.Renderer */:
                return this.renderer;
            case "Force" /* FunctionType.Force */:
                return this.forces;
        }
    }
    setFunctionList(type, list) {
        switch (type) {
            case "Emitter" /* FunctionType.Emitter */:
                this.emitter = list;
                break;
            case "Initializer" /* FunctionType.Initializer */:
                this.initializer = list;
                break;
            case "Operator" /* FunctionType.Operator */:
                this.operator = list;
                break;
            case "Renderer" /* FunctionType.Renderer */:
                this.renderer = list;
                break;
            case "Force" /* FunctionType.Force */:
                this.forces = list;
                break;
        }
    }
}
export class Particle {
    constructor(id, origProperties) {
        this.lifeDuration = 0;
        this.propLifeTime = 0;
        this.endCap = false;
        this.endCapDuration = 0;
        this.endCapDelay = 0;
        this.dead = false;
        this.id = id;
        const properties = _.cloneDeep(origProperties);
        this.lifeTime = properties.lifeTime;
        this._lifeTime = properties.lifeTime;
        this.radius = properties.radius;
        this._radius = properties.radius;
        this.alpha = properties.alpha;
        this._alpha = properties.alpha;
        this.roll = properties.roll;
        this._roll = properties.roll;
        this.pitch = properties.pitch;
        this._pitch = properties.pitch;
        this.yaw = properties.yaw;
        this._yaw = properties.yaw;
        this.rollSpeed = 0;
        this._rollSpeed = 0;
        this.pitchSpeed = 0;
        this._pitchSpeed = 0;
        this.yawSpeed = 0;
        this._yawSpeed = 0;
        this.trailLength = properties.trailLength;
        this._trailLength = properties.trailLength;
        this.sequence = properties.sequence;
        this._sequence = properties.sequence;
        // vector attributes
        this.velocity = properties.velocity;
        this._velocity = properties.velocity;
        this.position = properties.position;
        this._position = properties.position;
        this.color = properties.color;
        this._color = properties.color;
    }
    startEndcap() {
        this.endCap = true;
        if (this.endCapDelay <= 0) {
            this.dead = true;
        }
    }
    tick(delta) {
        this.lifeDuration += delta;
        if (this.endCap) {
            this.endCapDuration += delta;
            if (this.endCapDuration >= this.endCapDelay) {
                this.dead = true;
            }
        }
        this.propLifeTime = this.lifeDuration / this.lifeTime;
        this.lifeTime = this._lifeTime;
        this.radius = this._radius;
        this.alpha = this._alpha;
        this.roll = this._roll;
        this.pitch = this._pitch;
        this.yaw = this._yaw;
        this.rollSpeed = this._rollSpeed;
        this.pitchSpeed = this._pitchSpeed;
        this.yawSpeed = this._yawSpeed;
        this.trailLength = this._trailLength;
        this.sequence = this._sequence;
        this.color = this._color;
        this.position = this._position;
        this.velocity = this._velocity;
    }
    isAlive() {
        return !this.dead;
    }
}
