import { ParticleFunctions } from "./particle_function_util";
import { SpriteLoader, SpriteTexture } from "./sprite_loader";
import { Color } from "./util";
let curPopup;
export function isPopupActive() {
    return curPopup !== undefined;
}
export function openFunctionPopup(scene, type, callback) {
    if (curPopup)
        return;
    return new FunctionSelectionPopup(scene, type, callback);
}
export function openColorPopup(scene, oldColor, callback) {
    if (curPopup)
        return;
    return new ColorSelectPopup(scene, oldColor, callback);
}
export function openSpritePopup(scene, lastName, callback) {
    if (curPopup)
        return;
    return new SpriteSelectPopup(scene, lastName, callback);
}
class BasePopup {
    constructor(className, scene) {
        curPopup = this;
        this.scene = scene;
        let parent = scene.getHTMLElement();
        this.baseWindow = document.createElement("div");
        this.baseWindow.setAttribute("id", "PopupWindow");
        parent.appendChild(this.baseWindow);
        this.baseWindow.classList.add(className);
        this.closeButton = document.createElement("img");
        this.closeButton.classList.add("close");
        this.closeButton.src = "../res/cancel.svg";
        this.baseWindow.appendChild(this.closeButton);
        this.closeButton.addEventListener("click", () => {
            this.onClose();
            this.close();
        });
        this.confirmButton = document.createElement("div");
        this.confirmButton.classList.add("confirm");
        this.baseWindow.appendChild(this.confirmButton);
        this.confirmButton.innerHTML = "Confirm";
        this.confirmButton.addEventListener("click", () => {
            this.onConfirm();
            this.confirm();
        });
    }
    close() {
        this.baseWindow.remove();
        curPopup = undefined;
    }
    confirm() {
        this.close();
    }
}
class FunctionSelectionPopup extends BasePopup {
    constructor(scene, fType, callback) {
        super("FunctionPopup", scene);
        this.options = [];
        this.fType = fType;
        this.callback = callback;
        let icon = document.createElement("img");
        icon.src = "../res/search.svg";
        this.baseWindow.appendChild(icon);
        this.search = document.createElement("input");
        this.baseWindow.appendChild(this.search);
        this.search.focus();
        this.search.addEventListener("keydown", (e) => {
            if (e.key === "ArrowDown") {
                if (this.options.length === 0)
                    return;
                if (!this.getCurrentOption()) {
                    for (const option of this.options) {
                        if (!option.disabled) {
                            option.selected = true;
                            break;
                        }
                    }
                }
            }
            if (e.key === "Enter") {
                this.onConfirm();
                this.confirm();
            }
        });
        this.search.addEventListener("keyup", (e) => {
            if (e.key === "ArrowDown") {
                this.select.focus();
            }
        });
        this.search.addEventListener("input", () => {
            this.checkOptions(this.search.value);
        });
        this.select = document.createElement("select");
        this.baseWindow.appendChild(this.select);
        this.select.size = 20;
        this.select.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                this.onConfirm();
                this.confirm();
            }
            if (e.key === "ArrowUp") {
                if (this.options.length === 0)
                    return;
                let currentOption = this.getCurrentOption();
                if (currentOption && this.first) {
                    if (this.first === currentOption) {
                        this.search.focus();
                        this.search.selectionStart = this.search.selectionEnd =
                            this.search.value.length;
                    }
                }
            }
        });
        this.select.addEventListener("dblclick", (e) => {
            this.onConfirm();
            this.confirm();
        });
        const controlPanel = this.scene.getControlPanel();
        if (controlPanel) {
            let names = ParticleFunctions.getNamesForFunctionType(this.fType);
            names.sort();
            for (const name of names) {
                this.addOption(name);
            }
        }
    }
    addOption(name) {
        let option = document.createElement("option");
        option.innerHTML = name;
        this.select.add(option);
        this.options.push(option);
        if (this.options.length === 1)
            this.first = option;
    }
    checkOptions(text) {
        text = text.toLowerCase();
        let changed = false;
        for (const option of this.options) {
            if (text === "") {
                if (option.disabled === true)
                    changed = true;
                option.disabled = false;
                continue;
            }
            if (!option.value.toLowerCase().includes(text)) {
                if (option.disabled === false)
                    changed = true;
                option.disabled = true;
                option.selected = false;
            }
            else {
                if (option.disabled === true)
                    changed = true;
                option.disabled = false;
            }
        }
        if (changed)
            this.sortOptions();
    }
    sortOptions() {
        let dict = {};
        let enabled = [];
        let disabled = [];
        for (const option of this.options) {
            const name = option.value;
            dict[name] = option;
            if (!option.disabled) {
                enabled.push(name);
            }
            else {
                disabled.push(name);
            }
        }
        while (this.select.firstChild) {
            this.select.removeChild(this.select.lastChild);
        }
        let index = 0;
        let firstAdded = false;
        for (let i = 0; i < enabled.length; i++) {
            const name = enabled[i];
            const option = dict[name];
            this.select.add(option);
            if (!firstAdded) {
                this.first = option;
                firstAdded = true;
            }
            index++;
        }
        for (let i = 0; i < disabled.length; i++) {
            const name = disabled[i];
            const option = dict[name];
            this.select.add(option);
            index++;
        }
    }
    getCurrentOption() {
        let index = this.select.selectedIndex;
        if (index >= 0) {
            return this.select.options[index];
        }
        return;
    }
    onClose() { }
    onConfirm() {
        let option = this.getCurrentOption();
        if (option) {
            this.callback(option.value);
        }
    }
}
export class ColorSelectPopup extends BasePopup {
    constructor(scene, oldColor, callback) {
        super("ColorPopup", scene);
        this.mouseBarDown = false;
        this.mouseFieldDown = false;
        this.callback = callback;
        let hsv = oldColor.exportHSV();
        this.baseColor = Color.fromHSV(hsv.h, 1, 1);
        this.finalColor = oldColor;
        this.oldColor = oldColor;
        this.width = 200;
        this.height = 200;
        this.barWidth = 20;
        this.pickerCircle = {
            x: hsv.s * this.width,
            y: (1 - hsv.v) * this.height,
            width: 7,
            height: 7,
        };
        this.pickerBar = { y: (hsv.h / 360) * this.height, width: 5 };
        this.colorField = document.createElement("canvas");
        this.baseWindow.appendChild(this.colorField);
        this.colorField.setAttribute("id", "ColorField");
        this.fieldContext = this.colorField.getContext("2d");
        this.colorField.height = this.height;
        this.colorField.width = this.width;
        this.colorField.addEventListener("mousedown", (e) => {
            this.mouseFieldDown = true;
            const rect = this.colorBar.getBoundingClientRect();
            let x = e.clientX - (rect.left - this.width) + this.pickerCircle.width + 2;
            let y = e.clientY - rect.top - 2;
            x = Math.max(Math.min(x, this.width), 0);
            y = Math.max(Math.min(y, this.height), 0);
            this.pickerCircle.x = x;
            this.pickerCircle.y = y;
            this.setColorMap();
            this.setFinalColor(this.getFieldColor(x, y));
        });
        this.colorField.addEventListener("mousemove", (e) => {
            if (this.mouseFieldDown) {
                this.mouseFieldDown = true;
                const rect = this.colorBar.getBoundingClientRect();
                let x = e.clientX - (rect.left - this.width) + this.pickerCircle.width + 2;
                let y = e.clientY - rect.top - 2;
                x = Math.max(Math.min(x, this.width), 0);
                y = Math.max(Math.min(y, this.height), 0);
                this.pickerCircle.x = x;
                this.pickerCircle.y = y;
                this.setColorMap();
                this.setFinalColor(this.getFieldColor(x, y));
            }
        });
        document.addEventListener("mouseup", this.mouseUp.bind(this));
        this.colorBar = document.createElement("canvas");
        this.baseWindow.appendChild(this.colorBar);
        this.colorBar.setAttribute("id", "ColorBar");
        this.barContext = this.colorBar.getContext("2d");
        this.colorBar.addEventListener("mousedown", (e) => {
            this.mouseBarDown = true;
            const rect = this.colorBar.getBoundingClientRect();
            let y = e.clientY - rect.top;
            y = Math.max(Math.min(y, this.height), 0);
            this.pickerBar.y = y;
            this.resetColorBar();
            this.setBaseColor(this.getBarColor(y));
            this.setColorBar();
        });
        this.colorBar.addEventListener("mousemove", (e) => {
            if (this.mouseBarDown) {
                const rect = this.colorBar.getBoundingClientRect();
                let y = e.clientY - rect.top;
                y = Math.floor(Math.max(Math.min(y, 198), 0)); // makes sure its an int and in range 0-200
                this.pickerBar.y = y;
                this.resetColorBar();
                this.setBaseColor(this.getBarColor(y));
                this.setColorBar();
            }
        });
        document.addEventListener("mouseup", (this.eventHandler = () => {
            this.mouseBarDown = false;
            this.mouseFieldDown = false;
        }));
        this.colorBar.height = this.height;
        this.colorBar.width = this.barWidth;
        this.resetColorBar();
        this.setColorBar();
        this.setColorMap();
        // this.addRGBMap();
    }
    setBaseColor(baseColor) {
        this.baseColor = baseColor;
        this.finalColor = this.getFieldColor(this.pickerCircle.x, this.pickerCircle.y);
        this.setColorMap();
        this.onConfirm();
    }
    setFinalColor(finalColor) {
        this.finalColor = finalColor;
        this.onConfirm();
    }
    getBarColor(y) {
        const imageData = this.barContext.getImageData(5, y, 1, 1);
        return new Color(imageData.data[0], imageData.data[1], imageData.data[2]);
    }
    getFieldColor(x, y) {
        const imageData = this.fieldContext.getImageData(x, y, 1, 1);
        return new Color(imageData.data[0], imageData.data[1], imageData.data[2]);
    }
    mouseUp(e) {
        // console.log("UP!");
        //
    }
    setColorBar() {
        let context = this.barContext;
        let offset = Math.floor(this.pickerBar.width / 2);
        context.beginPath();
        context.lineWidth = 2;
        context.strokeStyle = "black";
        context.moveTo(0, this.pickerBar.y - offset);
        context.lineTo(20, this.pickerBar.y - offset);
        context.stroke();
        context.closePath();
        context.beginPath();
        context.lineWidth = 2;
        context.strokeStyle = "black";
        context.moveTo(0, this.pickerBar.y + offset);
        context.lineTo(20, this.pickerBar.y + offset);
        context.stroke();
        context.closePath();
    }
    resetColorBar() {
        let context = this.barContext;
        let gradient = context.createLinearGradient(0, 0, 0, this.height);
        gradient.addColorStop(0, "rgb(255, 0, 0)");
        gradient.addColorStop(0.84, "rgb(255, 0, 255)");
        gradient.addColorStop(0.67, "rgb(0, 0, 255)");
        gradient.addColorStop(0.49, "rgb(0, 255, 255)");
        gradient.addColorStop(0.33, "rgb(0, 255, 0)");
        gradient.addColorStop(0.15, "rgb(255, 255, 0)");
        gradient.addColorStop(1, "rgb(255, 0, 0)");
        // Fill it
        context.fillStyle = gradient;
        context.fillRect(0, 0, this.width, this.height);
    }
    setColorMap() {
        let context = this.fieldContext;
        let gradient = context.createLinearGradient(0, 0, this.width, 0);
        gradient.addColorStop(0, "white");
        gradient.addColorStop(1, this.baseColor.exportHTML(1));
        context.fillStyle = gradient;
        context.fillRect(0, 0, this.width, this.height);
        gradient = context.createLinearGradient(0, 0, 0, this.height);
        gradient.addColorStop(0, "rgba(0, 0, 0, 0)");
        gradient.addColorStop(1, "rgba(0, 0, 0, 1)");
        context.fillStyle = gradient;
        context.fillRect(0, 0, this.width, this.height);
        //Circle
        context.beginPath();
        context.arc(this.pickerCircle.x, this.pickerCircle.y, this.pickerCircle.width, 0, Math.PI * 2);
        context.strokeStyle = "black";
        context.stroke();
        context.closePath();
        //Circle
        context.beginPath();
        context.arc(this.pickerCircle.x, this.pickerCircle.y, this.pickerCircle.width + 1, 0, Math.PI * 2);
        context.strokeStyle = "white";
        context.stroke();
        context.closePath();
    }
    addRGBMap() {
        let context = this.fieldContext;
        this.colorField.height = this.height;
        this.colorField.width = this.width;
        // context.clearRect(0, 0, this.width, this.height);
        let gradient = context.createLinearGradient(0, 0, this.width, 0);
        //Color Stops
        gradient.addColorStop(0, "rgb(255, 0, 0)");
        gradient.addColorStop(0.15, "rgb(255, 0, 255)");
        gradient.addColorStop(0.33, "rgb(0, 0, 255)");
        gradient.addColorStop(0.49, "rgb(0, 255, 255)");
        gradient.addColorStop(0.67, "rgb(0, 255, 0)");
        gradient.addColorStop(0.84, "rgb(255, 255, 0)");
        gradient.addColorStop(1, "rgb(255, 0, 0)");
        //Fill it
        context.fillStyle = gradient;
        context.fillRect(0, 0, this.width, this.height);
        //Apply black and white
        gradient = context.createLinearGradient(0, 0, 0, this.height);
        gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
        gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
        gradient.addColorStop(0.5, "rgba(0, 0, 0, 0)");
        gradient.addColorStop(1, "rgba(0, 0, 0, 1)");
        context.fillStyle = gradient;
        context.fillRect(0, 0, this.width, this.height);
        //Circle
        context.beginPath();
        context.arc(this.pickerCircle.x, this.pickerCircle.y, this.pickerCircle.width, 0, Math.PI * 2);
        context.strokeStyle = "black";
        context.stroke();
        context.closePath();
    }
    onClose() {
        // document.removeEventListener("mouseup", this.mouseUp);
        document.removeEventListener("mouseup", this.eventHandler);
        this.callback(this.oldColor);
    }
    onConfirm() {
        this.callback(this.finalColor);
    }
}
let lastSearch = "";
class SpriteSelectPopup extends BasePopup {
    constructor(scene, lastPath, callback) {
        super("SpritePopup", scene);
        this.items = new Map();
        this.callback = callback;
        this.curPath = lastPath;
        this.curName = lastPath;
        this.origPath = lastPath;
        this.search = document.createElement("input");
        this.baseWindow.appendChild(this.search);
        this.search.focus();
        this.search.addEventListener("input", (e) => this.onSearchChange());
        this.results = document.createElement("div");
        this.baseWindow.appendChild(this.results);
        this.results.classList.add("ResultBox");
        // for lazy loading the images
        const observer = new IntersectionObserver((entries) => {
            for (const entry of entries) {
                const target = entry.target;
                if (target.item && entry.isIntersecting)
                    target.item.load();
            }
        });
        this.loader = SpriteLoader.Create();
        this.loader.getAll().then((data) => {
            for (const sprite of data) {
                let spritePath = sprite.path;
                const animated = SpriteTexture.isSheetFromData(sprite) == 2 /* SpriteSheetType.Animated */;
                // if (animated) {
                // 	spritePath = (
                // 		sprite.sequences![0] as { [index: number]: string }
                // 	)[0];
                // }
                const item = new PopupSpriteItem(this.results, sprite.name, spritePath, animated);
                item.registerClick((item) => {
                    this.curName = item.name;
                    this.curPath = item.path;
                    item.setActive(true);
                    this.lastItem?.setActive(false);
                    this.lastItem = item;
                    this.onConfirm();
                });
                this.items.set(sprite.name, item);
                observer.observe(item.root);
                if (spritePath == lastPath) {
                    item.setActive(true);
                    this.lastItem = item;
                    item.root.scrollIntoView();
                }
            }
        });
    }
    onSearchChange() {
        const text = this.search.value;
        for (const [name, item] of this.items) {
            if (!name.toLowerCase().includes(text.toLowerCase())) {
                item.hide();
            }
            else {
                item.show();
            }
        }
    }
    onClose() {
        this.callback(this.origPath, this.origPath);
    }
    onConfirm() {
        this.callback(this.curName, this.curPath);
    }
}
class PopupSpriteItem {
    constructor(parent, name, path, animated = false) {
        this.parent = parent;
        this.name = name;
        this.path = path;
        this.animated = animated;
        this.isLoaded = false;
        this.root = document.createElement("div");
        this.root.item = this;
        this.parent.appendChild(this.root);
        this.root.classList.add("ResultItem");
    }
    registerClick(callback) {
        this.clickCallback = callback;
    }
    load() {
        if (this.isLoaded)
            return;
        this.isLoaded = true;
        this.image = document.createElement("img");
        this.root.appendChild(this.image);
        const nameLabel = document.createElement("p");
        nameLabel.innerHTML = this.name;
        this.root.appendChild(nameLabel);
        if (this.animated) {
            const animIcon = document.createElement("div");
            animIcon.classList.add("anim");
            this.root.appendChild(animIcon);
        }
        this.root.addEventListener("click", () => {
            if (this.clickCallback)
                this.clickCallback(this);
        });
        this.image.src = this.path;
    }
    show() {
        this.root.classList.remove("hidden");
    }
    hide() {
        this.root.classList.add("hidden");
    }
    setActive(state) {
        if (state) {
            this.root.classList.add("active");
        }
        else {
            this.root.classList.remove("active");
        }
    }
}
