import * as THREE from "three";
import { MathUtils } from "three";
import { Orientation, ShortenNumber, Vector } from "./util";
import { ParticleFunctions } from "./particle_function_util";
export class ControlPoints {
    constructor(scene, camera, renderer, rootElement) {
        this.points = new Map();
        this.pointOrigins = new Map();
        this.mover = [];
        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;
        this.rootElement = rootElement;
        if (!rootElement)
            return;
        this.pointsPanel = document.createElement("div");
        this.pointsPanel.setAttribute("id", "PointsPanel");
        rootElement.appendChild(this.pointsPanel);
        const title = document.createElement("div");
        title.setAttribute("id", "PointsTitle");
        this.pointsPanel.appendChild(title);
        title.innerHTML = "Control Points";
        title.classList.add("noSelect");
        const controlClose = document.createElement("img");
        controlClose.setAttribute("id", "PointsClose");
        controlClose.src = "../res/cancel.svg";
        controlClose.addEventListener("click", () => {
            this.close();
        });
        this.pointsPanel.appendChild(controlClose);
        this.pointsList = document.createElement("div");
        this.pointsList.setAttribute("id", "PointsList");
        this.pointsPanel.appendChild(this.pointsList);
    }
    addPoint(origin, index) {
        if (this.pointOrigins.has(index)) {
            this.pointOrigins.get(index)?.push(origin);
        }
        else {
            this.pointOrigins.set(index, [origin]);
        }
        if (this.points.has(index))
            return this.points.get(index);
        const newPoint = new ControlPoint(new Vector(0, 0, 0));
        this.points.set(index, newPoint);
        if (!this.pointsList)
            return this.points.get(index);
        const pointEntry = document.createElement("div");
        pointEntry.setAttribute("id", index.toString());
        pointEntry.classList.add("ControlPointContainer");
        this.pointsList.appendChild(pointEntry);
        const pointTitle = document.createElement("div");
        pointTitle.classList.add("ControlPointTitle");
        pointTitle.innerHTML = "Point #" + index;
        pointEntry.appendChild(pointTitle);
        const pointInner = document.createElement("div");
        pointInner.classList.add("ControlPointContainerInner");
        pointEntry.appendChild(pointInner);
        const mover = new ControlPointMover(this, pointInner, newPoint);
        this.mover[index] = mover;
        this.sortPoints();
        return this.points.get(index);
    }
    sortPoints() {
        if (!this.pointsList)
            return;
        [...this.pointsList.children]
            .sort((a, b) => (parseFloat(a.id) > parseFloat(b.id) ? 1 : -1))
            .forEach((node) => this.pointsList.appendChild(node));
    }
    removePoint(origin, index) {
        if (!this.pointOrigins.has(index))
            return false;
        const origins = this.pointOrigins.get(index);
        if (!origins || !origins.includes(origin))
            return false;
        const newOrigins = origins.filter((func) => func !== origin);
        if (newOrigins.length < 1) {
            this.points.delete(index);
            this.pointOrigins.delete(index);
            if (!this.pointsList)
                return true;
            for (const child of this.pointsList.children) {
                if (child.id === index.toString()) {
                    child.remove();
                    if (this.mover[index])
                        this.mover[index].hidePoint();
                    this.mover.splice(index);
                }
            }
            return true;
        }
        else {
            this.pointOrigins.set(index, newOrigins);
        }
        return false;
    }
    renderMover() {
        for (const mover of this.mover) {
            if (mover)
                mover.render();
        }
    }
    show() { }
    close() { }
    setPosition(index, pos) {
        if (!this.points.has(index))
            return false;
        const point = this.points.get(index);
        point.updateFromVec(pos);
    }
}
class ControlPointMover {
    constructor(points, root, point) {
        this.type = "Position";
        this.shown = false;
        this.center = new THREE.Vector3();
        this.arrows = [];
        this.arrowIntersects = [];
        this.arrowObjects = [];
        this.planes = [];
        this.planeIntersects = [];
        this.planeObjects = [];
        this.moveType = 0 /* MoveDirections.X */;
        this.mouseDown = false;
        this.mousePos = { x: 0, y: 0 };
        this.points = points;
        this.scene = points.scene;
        this.camera = points.camera;
        this.renderer = points.renderer;
        this.controlPoint = point;
        const positionBox = document.createElement("div");
        positionBox.setAttribute("id", "PositionButtonBox");
        root.appendChild(positionBox);
        const positionButton = document.createElement("div");
        positionButton.setAttribute("id", "PositionButton");
        positionBox.appendChild(positionButton);
        this.positionButton = positionButton;
        positionButton.addEventListener("click", () => {
            if (this.points.activeMover && this.points.activeMover !== this) {
                this.points.activeMover.hidePoint();
            }
            if (this.shown) {
                this.hidePoint();
                this.points.activeMover = undefined;
            }
            else {
                this.showPoint();
                this.points.activeMover = this;
            }
        });
        const positionTypeButton = document.createElement("div");
        positionTypeButton.setAttribute("id", "TypeSelection");
        positionBox.appendChild(positionTypeButton);
        positionTypeButton.addEventListener("click", () => {
            this.toggleType();
        });
        const xInput = document.createElement("input");
        xInput.value = "0";
        root.appendChild(xInput);
        xInput.addEventListener("input", (event) => {
            const newVal = ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, xInput.value);
            this.controlPoint.updateX(isNaN(newVal) ? 0 : newVal);
        });
        xInput.addEventListener("change", (event) => {
            const newVal = ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, xInput.value);
            xInput.value = newVal ? newVal.toString() : "0";
        });
        const yInput = document.createElement("input");
        yInput.value = "0";
        root.appendChild(yInput);
        yInput.addEventListener("input", (event) => {
            const newVal = ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, yInput.value);
            this.controlPoint.updateY(isNaN(newVal) ? 0 : newVal);
        });
        yInput.addEventListener("change", (event) => {
            const newVal = ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, yInput.value);
            yInput.value = newVal ? newVal.toString() : "0";
        });
        const zInput = document.createElement("input");
        zInput.value = "0";
        root.appendChild(zInput);
        zInput.addEventListener("input", (event) => {
            const newVal = ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, zInput.value);
            this.controlPoint.updateZ(isNaN(newVal) ? 0 : newVal);
        });
        zInput.addEventListener("change", (event) => {
            const newVal = ParticleFunctions.parseVal("Number" /* FieldTypes.Number */, zInput.value);
            zInput.value = newVal ? newVal.toString() : "0";
        });
        this.inputs = {
            x: xInput,
            y: yInput,
            z: zInput,
        };
    }
    toggleType() {
        if (this.type === "Position") {
            this.type = "Orientation";
            this.positionButton.classList.add("orientation");
        }
        else {
            this.type = "Position";
            this.positionButton.classList.remove("orientation");
        }
    }
    showPoint() {
        this.shown = true;
        this.positionButton.classList.add("active");
        const origin = this.controlPoint.getThreePosition();
        const distance = this.camera.position.clone().sub(origin); // Note: depending on the order of subtracting, you'll get either forward or backward direction
        const direction = distance.normalize(); // Length of a normalized vector is always 1, while preserving vector direction
        this.center = this.camera.position.clone().add(direction.multiplyScalar(-1));
        // point
        const material = new THREE.MeshBasicMaterial({ color: 0xc83cc8 });
        const circleGeometry = new THREE.CircleGeometry(0.004, 16);
        this.circle = new THREE.Mesh(circleGeometry, material);
        this.circle.quaternion.copy(this.camera.quaternion);
        this.circle.position.set(this.center.x, this.center.y, this.center.z);
        this.scene.add(this.circle);
        // arrows and planes
        const length = 0.2;
        const arrowData = [
            [new THREE.Vector3(1, 0, 0), 0xc81e1e],
            [new THREE.Vector3(0, 1, 0), 0x32c81e],
            [new THREE.Vector3(0, 0, 1), 0x1e6ec8],
        ];
        for (const [dir, hex] of arrowData) {
            const color = new THREE.Color(hex);
            const arrow = new CustomArrow(this.scene, this.center, dir, color);
            this.arrows.push(arrow);
            this.arrowObjects = this.arrowObjects.concat(arrow.getObjects());
            const plane = new CustomPlane(this.scene, this.center, dir, color);
            this.planes.push(plane);
            this.planeObjects = this.planeObjects.concat(plane.plane);
        }
        this.moveHandler = (event) => {
            this.checkIntersection(event);
        };
        document.addEventListener("mousemove", this.moveHandler);
        this.downHandler = (event) => {
            if (event.button === 0) {
                if (!this.curArrow && !this.curPlane)
                    return;
                for (const otherArrow of this.arrows) {
                    if (this.curArrow === otherArrow)
                        continue;
                    otherArrow.hide();
                }
                for (const otherPlane of this.planes) {
                    if (this.curPlane === otherPlane)
                        continue;
                    otherPlane.hide();
                }
                if (this.curArrow) {
                    const curDirection = this.curArrow.direction;
                    if (curDirection.x === 1)
                        this.moveType = 0 /* MoveDirections.X */;
                    if (curDirection.y === 1)
                        this.moveType = 1 /* MoveDirections.Y */;
                    if (curDirection.z === 1)
                        this.moveType = 2 /* MoveDirections.Z */;
                    this.curObj = this.curArrow;
                }
                if (this.curPlane) {
                    const curDirection = this.curPlane.direction;
                    if (curDirection.x === 1)
                        this.moveType = 3 /* MoveDirections.XPlane */;
                    if (curDirection.y === 1)
                        this.moveType = 4 /* MoveDirections.YPlane */;
                    if (curDirection.z === 1)
                        this.moveType = 5 /* MoveDirections.ZPlane */;
                    this.curObj = this.curPlane;
                }
                this.mouseDown = true;
                this.initMovement(event);
            }
        };
        document.addEventListener("mousedown", this.downHandler);
        this.upHandler = (event) => {
            if (event.button === 0) {
                this.mouseDown = false;
                for (const arrow of this.arrows) {
                    arrow.reset();
                }
                for (const plane of this.planes) {
                    plane.reset();
                }
                if (this.curObj)
                    this.curObj.resetColor();
                this.curObj = undefined;
            }
        };
        document.addEventListener("mouseup", this.upHandler);
    }
    render() {
        if (!this.shown)
            return;
        const origin = this.controlPoint.getThreePosition();
        const distance = this.camera.position.clone().sub(origin); // Note: depending on the order of subtracting, you'll get either forward or backward direction
        const direction = distance.normalize(); // Length of a normalized vector is always 1, while preserving vector direction
        this.center = this.camera.position.clone().add(direction.multiplyScalar(-1));
        if (this.circle) {
            this.circle.quaternion.copy(this.camera.quaternion);
            this.circle.position.set(this.center.x, this.center.y, this.center.z);
        }
        for (const arrow of this.arrows) {
            arrow.update(this.center);
        }
        for (const plane of this.planes) {
            plane.update(this.center);
        }
    }
    hidePoint() {
        this.shown = false;
        this.positionButton.classList.remove("active");
        if (this.circle)
            this.scene.remove(this.circle);
        for (const arrow of this.arrows) {
            arrow.destroy();
        }
        for (const plane of this.planes) {
            plane.destroy();
        }
        this.arrows = [];
        this.arrowIntersects = [];
        this.planes = [];
        this.planeIntersects = [];
        if (this.moveHandler)
            document.removeEventListener("mousemove", this.moveHandler);
    }
    initMovement(event) {
        this.mousePos = {
            x: event.clientX,
            y: event.clientY,
        };
        const rect = this.renderer.domElement.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        const y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        const mouse3D = new THREE.Vector3(x, y, 0.5);
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse3D, this.camera);
        let plane;
        const intersection = new THREE.Vector3();
        const newOffset = this.curOffset ? this.curOffset.clone() : new THREE.Vector3();
        const center = this.controlPoint.getThreePosition();
        switch (this.moveType) {
            case 0 /* MoveDirections.X */:
            case 1 /* MoveDirections.Y */:
            case 5 /* MoveDirections.ZPlane */:
                plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
                break;
            case 2 /* MoveDirections.Z */:
            case 4 /* MoveDirections.YPlane */:
                plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
                break;
            case 3 /* MoveDirections.XPlane */:
                plane = new THREE.Plane(new THREE.Vector3(1, 0, 0), 0);
                break;
        }
        raycaster.ray.intersectPlane(plane, intersection);
        this.curOffset = center.clone().sub(intersection);
    }
    checkIntersection(event) {
        const rect = this.renderer.domElement.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        const y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        const mouse3D = new THREE.Vector3(x, y, 0.5);
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse3D, this.camera);
        if (!this.curPlane) {
            const arrowIntersects = raycaster.intersectObjects(this.arrowObjects);
            const actualArrowIntersects = [];
            if (arrowIntersects.length > 0) {
                const object = arrowIntersects[0].object;
                actualArrowIntersects.push(object);
                const arrow = object.arrowHandle;
                if (arrow) {
                    arrow.mark();
                    this.curArrow = arrow;
                }
            }
            else {
                this.curArrow = undefined;
            }
            for (const intersect of this.arrowIntersects) {
                if (!actualArrowIntersects.includes(intersect)) {
                    const arrow = intersect.arrowHandle;
                    if (arrow) {
                        if (arrow === this.curObj)
                            continue;
                        arrow.resetColor();
                    }
                }
            }
            this.arrowIntersects = actualArrowIntersects;
        }
        if (!this.curArrow) {
            const planeIntersects = raycaster.intersectObjects(this.planeObjects);
            const actualPlaneIntersects = [];
            if (planeIntersects.length > 0) {
                const object = planeIntersects[0].object;
                actualPlaneIntersects.push(object);
                const plane = object.planeHandle;
                if (plane) {
                    plane.mark();
                    this.curPlane = plane;
                }
            }
            else {
                this.curPlane = undefined;
            }
            for (const intersect of this.planeIntersects) {
                if (!actualPlaneIntersects.includes(intersect)) {
                    const plane = intersect.planeHandle;
                    if (plane) {
                        if (plane === this.curObj)
                            continue;
                        plane.resetColor();
                    }
                }
            }
            this.planeIntersects = actualPlaneIntersects;
        }
        if (!this.mouseDown || !this.center)
            return;
        let plane;
        const intersection = new THREE.Vector3();
        const newOffset = this.curOffset ? this.curOffset.clone() : new THREE.Vector3();
        const center = this.controlPoint.getThreePosition();
        switch (this.moveType) {
            case 0 /* MoveDirections.X */:
            case 1 /* MoveDirections.Y */:
            case 5 /* MoveDirections.ZPlane */:
                plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
                break;
            case 2 /* MoveDirections.Z */:
            case 4 /* MoveDirections.YPlane */:
                plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
                break;
            case 3 /* MoveDirections.XPlane */:
                plane = new THREE.Plane(new THREE.Vector3(1, 0, 0), 0);
                break;
        }
        raycaster.ray.intersectPlane(plane, intersection);
        if (this.moveType === 0 /* MoveDirections.X */) {
            intersection.y = center.y;
            intersection.z = center.z;
            newOffset.y = 0;
            newOffset.z = 0;
        }
        else if (this.moveType === 1 /* MoveDirections.Y */) {
            intersection.x = center.x;
            intersection.z = center.z;
            newOffset.x = 0;
            newOffset.z = 0;
        }
        else if (this.moveType === 2 /* MoveDirections.Z */) {
            intersection.y = center.y;
            intersection.x = center.x;
            newOffset.y = 0;
            newOffset.x = 0;
        }
        intersection.add(newOffset);
        this.updateFromThree(intersection);
    }
    updateFromThree(vec) {
        this.controlPoint.updateFromThree(vec);
        this.inputs.x.value = ShortenNumber(vec.x, 2).toString();
        this.inputs.y.value = ShortenNumber(vec.y, 2).toString();
        this.inputs.z.value = ShortenNumber(vec.z, 2).toString();
    }
}
class CustomArrow {
    constructor(scene, position, direction, color) {
        this.sizeMultiplier = 1;
        this.hidden = false;
        this.scene = scene;
        this.position = position;
        this.direction = direction;
        this.origColor = color;
        const endPos = position
            .clone()
            .add(direction.clone().multiplyScalar(0.25 * this.sizeMultiplier));
        const arrowTipPos = position
            .clone()
            .add(direction.clone().multiplyScalar(0.2 * this.sizeMultiplier));
        const material = new THREE.MeshBasicMaterial({
            color: color,
            side: THREE.DoubleSide,
        });
        const tubeGeometry = new THREE.TubeBufferGeometry(new THREE.CatmullRomCurve3([position, endPos]), 1, 0.001 * this.sizeMultiplier, 16, false);
        const coneGeometry = new THREE.ConeGeometry(0.01 * this.sizeMultiplier, 0.05 * this.sizeMultiplier, 16, 1, false);
        this.arrowLine = new THREE.Mesh(tubeGeometry, material);
        this.arrowTip = new THREE.Mesh(coneGeometry, material);
        this.arrowLine.arrowHandle = this;
        this.arrowTip.arrowHandle = this;
        this.arrowTip.position.set(arrowTipPos.x, arrowTipPos.y, arrowTipPos.z);
        this.arrowTip.rotateX(MathUtils.degToRad(90 * direction.z));
        this.arrowTip.rotateZ(MathUtils.degToRad(-90 * direction.x));
        scene.add(this.arrowLine);
        scene.add(this.arrowTip);
        const raycastTubeGeometry = new THREE.TubeBufferGeometry(new THREE.CatmullRomCurve3([position, endPos]), 1, 0.025 * this.sizeMultiplier, 16, false);
        const raycastMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(0x000000),
            side: THREE.DoubleSide,
        });
        this.raycastTube = new THREE.Mesh(raycastTubeGeometry, raycastMaterial);
        this.raycastTube.arrowHandle = this;
    }
    mark() {
        this.arrowLine.material.color.setHex(0xffff00);
    }
    resetColor() {
        this.arrowLine.material.color.set(this.origColor);
    }
    reset() {
        if (this.hidden) {
            this.scene.add(this.arrowLine);
            this.scene.add(this.arrowTip);
            this.hidden = false;
        }
    }
    hide() {
        this.scene.remove(this.arrowLine);
        this.scene.remove(this.arrowTip);
        this.hidden = true;
    }
    getObjects() {
        return [this.raycastTube];
    }
    update(position) {
        this.position = position;
        const endPos = position
            .clone()
            .add(this.direction.clone().multiplyScalar(0.22 * this.sizeMultiplier));
        const arrowTipPos = position
            .clone()
            .add(this.direction.clone().multiplyScalar(0.2 * this.sizeMultiplier));
        this.arrowLine.geometry.copy(new THREE.TubeBufferGeometry(new THREE.CatmullRomCurve3([position, endPos]), 1, 0.001 * this.sizeMultiplier, 16, false));
        this.arrowTip.position.set(arrowTipPos.x, arrowTipPos.y, arrowTipPos.z);
        this.raycastTube.geometry.copy(new THREE.TubeBufferGeometry(new THREE.CatmullRomCurve3([position, endPos]), 1, 0.025 * this.sizeMultiplier, 16, false));
    }
    destroy() {
        this.scene.remove(this.arrowTip);
        this.scene.remove(this.arrowLine);
    }
}
class CustomPlane {
    constructor(scene, position, direction, color) {
        this.sizeMultiplier = 1.5;
        this.hidden = false;
        this.scene = scene;
        this.position = position;
        this.direction = direction.clone();
        this.origColor = color;
        const newDirection = direction
            .clone()
            .applyAxisAngle(new THREE.Vector3(0, 0, 1), MathUtils.degToRad(90));
        const offsetScale = 0.055 * this.sizeMultiplier;
        this.offset = new THREE.Vector3((1 - direction.x) * offsetScale, (1 - direction.y) * offsetScale, (1 - direction.z) * offsetScale);
        const geometry = new THREE.PlaneGeometry(0.018 * this.sizeMultiplier, 0.018 * this.sizeMultiplier);
        const material = new THREE.MeshBasicMaterial({
            color: color,
            side: THREE.DoubleSide,
        });
        this.plane = new THREE.Mesh(geometry, material);
        this.plane.setRotationFromAxisAngle(newDirection, Math.PI / 2);
        this.plane.planeHandle = this;
        scene.add(this.plane);
    }
    mark() {
        this.plane.material.color.setHex(0xffff00);
    }
    resetColor() {
        this.plane.material.color.set(this.origColor);
    }
    reset() {
        this.resetColor();
        if (this.hidden) {
            this.scene.add(this.plane);
            this.hidden = false;
        }
    }
    hide() {
        this.scene.remove(this.plane);
        this.hidden = true;
    }
    update(position) {
        this.position = position;
        const newPos = this.position.clone().add(this.offset);
        this.plane.position.set(newPos.x, newPos.y, newPos.z);
    }
    destroy() {
        this.scene.remove(this.plane);
    }
}
export class ControlPoint {
    // private callbacks: Set<(difference: Vector) => void> = new Set();
    constructor(position, orientation) {
        this.callbacks = new Map();
        this.curCallbackID = 0;
        this.position = position;
        this.orientation = orientation ?? new Orientation(0, 0, 0);
    }
    getThreePosition() {
        return this.position.exportThree();
    }
    getPosition() {
        return this.position;
    }
    getX() {
        return this.position.x;
    }
    getY() {
        return this.position.y;
    }
    getZ() {
        return this.position.z;
    }
    registerChangeCallback(callback) {
        this.callbacks.set(this.curCallbackID, callback);
        this.curCallbackID++;
        return this.curCallbackID - 1;
    }
    unregisterChangeCallback(callbackID) {
        return this.callbacks.delete(callbackID);
    }
    onChange(oldPos) {
        const difference = this.position.sub(oldPos);
        for (const [id, callback] of this.callbacks) {
            callback(this.position, difference);
        }
    }
    update(x, y, z) {
        const oldPos = this.position.clone();
        this.position.x = x;
        this.position.y = y;
        this.position.z = z;
        this.onChange(oldPos);
    }
    updateX(x) {
        const oldPos = this.position.clone();
        this.position.x = x;
        this.onChange(oldPos);
    }
    updateY(y) {
        const oldPos = this.position.clone();
        this.position.y = y;
        this.onChange(oldPos);
    }
    updateZ(z) {
        const oldPos = this.position.clone();
        this.position.z = z;
        this.onChange(oldPos);
    }
    updateFromThree(vec) {
        const oldPos = this.position.clone();
        this.position.x = vec.x;
        this.position.y = vec.y;
        this.position.z = vec.z;
        this.onChange(oldPos);
    }
    updateFromVec(vec) {
        this.position = vec;
    }
}
