import * as THREE from "three";
import { MathUtils } from "three";
import { Color, compareEndcap, EndCapState, generateUniqueID, interpolate, interpolateColors, interpolateVectors, randomNumber, randomSign, Vector, } from "./util";
export var ParticleFloatAttributes;
(function (ParticleFloatAttributes) {
    ParticleFloatAttributes["lifeTime"] = "lifeTime";
    ParticleFloatAttributes["radius"] = "radius";
    ParticleFloatAttributes["alpha"] = "alpha";
    ParticleFloatAttributes["roll"] = "roll";
    ParticleFloatAttributes["pitch"] = "pitch";
    ParticleFloatAttributes["yaw"] = "yaw";
    ParticleFloatAttributes["trailLength"] = "trailLength";
})(ParticleFloatAttributes || (ParticleFloatAttributes = {}));
export var ParticleRotationAttributes;
(function (ParticleRotationAttributes) {
    ParticleRotationAttributes["Roll"] = "roll";
    ParticleRotationAttributes["Pitch"] = "pitch";
    ParticleRotationAttributes["Yaw"] = "yaw";
})(ParticleRotationAttributes || (ParticleRotationAttributes = {}));
export var ParticleVectorAttributes;
(function (ParticleVectorAttributes) {
    ParticleVectorAttributes["position"] = "position";
    ParticleVectorAttributes["color"] = "color";
})(ParticleVectorAttributes || (ParticleVectorAttributes = {}));
const ParticlePropertiesTypes = {
    lifeTime: "Number" /* FieldTypes.Number */,
    radius: "Number" /* FieldTypes.Number */,
    alpha: "Number" /* FieldTypes.Number */,
    roll: "Number" /* FieldTypes.Number */,
    velocity: "Vector" /* FieldTypes.Vector */,
    position: "Vector" /* FieldTypes.Vector */,
    color: "Color" /* FieldTypes.Color */,
    sequence: "Number" /* FieldTypes.Number */,
};
export { ParticlePropertiesTypes };
let allFunctions = {};
export function GetFunctionFromID(id) {
    return allFunctions[id];
}
/**
 * A particle function that influences particles behavior.
 */
export class BaseParticleFunction {
    constructor(funcType) {
        this._disabled = false;
        this.id = generateUniqueID();
        this.funcType = funcType;
        allFunctions[this.id] = this;
    }
    /**
     * Get the arguments this function needs with its type.
     * @returns
     */
    static getArgs() {
        return {};
    }
    set disabled(state) {
        // disabled state actually changed
        if (state !== this._disabled) {
            this._disabled = state;
            if (state)
                this.onDestroy();
            if (!state)
                this.onInit();
        }
    }
    get disabled() {
        return this._disabled;
    }
    /**
     * Register a new CP for this
     * @param index
     * @returns
     */
    registerCP(index) {
        if (this.disabled)
            return;
        if (!this.system)
            throw Error("No system found for particle function");
        if (!this.cps)
            this.cps = new Map();
        const cp = this.system.controlPoints.addPoint(this, index);
        this.cps.set(index, cp);
        return cp;
    }
    /**
     * Called once this function is added to the system.
     */
    onInit() {
        // do something?
    }
    /**
     * Called when this function gets removed or reconstructed.
     */
    onDestroy() {
        if (this.system && this.cps) {
            for (const [index, cp] of this.cps) {
                this.system.controlPoints.removePoint(this, index);
            }
        }
    }
    // type checks
    isEmitter() {
        return false;
    }
    isInitializer() {
        return false;
    }
    isOperator() {
        return false;
    }
    isRenderer() {
        return false;
    }
    isForce() {
        return false;
    }
}
class ParticleFunction {
    static getArgs() {
        return {};
    }
}
export class BaseParticleInitializer extends BaseParticleFunction {
    constructor() {
        super("Initializer" /* FunctionType.Initializer */);
        this.endCapState = EndCapState.ALWAYS;
    }
    isInitializer() {
        return true;
    }
}
export var ParticleInitializer;
(function (ParticleInitializer) {
    class PositionWithinSphereRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.cpNumber = 0;
            this.minRadius = 0;
            this.maxRadius = 0;
            this.distribution = new Vector(1, 1, 1);
            if (options) {
                this.cpNumber = options.cpNumber ?? this.cpNumber;
                this.minRadius = options.minRadius ?? this.minRadius;
                this.maxRadius = options.maxRadius ?? this.maxRadius;
                this.distribution = options.distribution ?? this.distribution;
            }
        }
        static getArgs() {
            return {
                cpNumber: ["ControlPoint" /* FieldTypes.ControlPoint */, 0],
                minRadius: ["Number" /* FieldTypes.Number */, 0],
                maxRadius: ["Number" /* FieldTypes.Number */, 0],
                distribution: ["Vector" /* FieldTypes.Vector */, "1 1 1"],
            };
        }
        getOptions() {
            return {
                cpNumber: this.cpNumber,
                minRadius: this.minRadius,
                maxRadius: this.maxRadius,
                distribution: this.distribution,
            };
        }
        onInit() {
            this.originCP = this.registerCP(this.cpNumber);
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            particle._position = this.randomVec();
            return particle;
        }
        getOrigin() {
            if (!this.originCP)
                return new Vector(0, 0, 0);
            return this.originCP.getPosition();
        }
        randomVec() {
            const l = randomNumber(this.minRadius, this.maxRadius);
            let lq = Math.pow(l, 2);
            const xq = randomNumber(0, lq);
            const yq = randomNumber(0, lq - xq);
            const zq = lq - xq - yq;
            let randomVec = new Vector(randomSign(Math.sqrt(xq)) * this.distribution.x, randomSign(Math.sqrt(yq)) * this.distribution.y, randomSign(Math.sqrt(zq)) * this.distribution.z);
            return randomVec.add(this.getOrigin());
        }
    }
    ParticleInitializer.PositionWithinSphereRandom = PositionWithinSphereRandom;
    class PositionAlongRing extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.cpNumber = 0;
            this.evenDistributionCount = -1;
            this.evenDistribution = false;
            this.radius = 1;
            this.thickness = 0;
            this.minSpeed = 0;
            this.maxSpeed = 0;
            this.roll = 0;
            this.pitch = 0;
            this.yaw = 0;
            this.curAngle = 0;
            if (options) {
                this.cpNumber = options.cpNumber ?? this.cpNumber;
                this.evenDistributionCount =
                    options.evenDistributionCount ?? this.evenDistributionCount;
                this.evenDistribution = options.evenDistribution ?? this.evenDistribution;
                this.radius = options.radius ?? this.radius;
                this.thickness = options.thickness ?? this.thickness;
                this.minSpeed = options.minSpeed ?? this.minSpeed;
                this.maxSpeed = options.maxSpeed ?? this.maxSpeed;
                this.roll = options.roll ?? this.roll;
                this.pitch = options.pitch ?? this.pitch;
                this.yaw = options.yaw ?? this.yaw;
            }
        }
        static getArgs() {
            return {
                cpNumber: ["ControlPoint" /* FieldTypes.ControlPoint */, 0],
                evenDistributionCount: ["Number" /* FieldTypes.Number */, -1],
                radius: ["Number" /* FieldTypes.Number */, 1],
                thickness: ["Number" /* FieldTypes.Number */, 0],
                minSpeed: ["Number" /* FieldTypes.Number */, 0],
                maxSpeed: ["Number" /* FieldTypes.Number */, 0],
                roll: ["Number" /* FieldTypes.Number */, 0],
                pitch: ["Number" /* FieldTypes.Number */, 0],
                yaw: ["Number" /* FieldTypes.Number */, 0],
                evenDistribution: ["Bool" /* FieldTypes.Boolean */, false],
            };
        }
        getOptions() {
            return {
                cpNumber: this.cpNumber,
                evenDistributionCount: this.evenDistributionCount,
                evenDistribution: this.evenDistribution,
                radius: this.radius,
                thickness: this.thickness,
                minSpeed: this.minSpeed,
                maxSpeed: this.maxSpeed,
                roll: this.roll,
                pitch: this.pitch,
                yaw: this.yaw,
            };
        }
        onInit() {
            this.originCP = this.registerCP(this.cpNumber);
            const p = new THREE.Vector3(1, 0, 0);
            p.applyEuler(new THREE.Euler(MathUtils.degToRad(this.roll), MathUtils.degToRad(this.pitch), MathUtils.degToRad(this.yaw)));
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            if (!this.evenDistribution) {
                particle._position = this.randomVec();
            }
            else {
                particle._position = this.nextVec();
            }
            const randomSpeed = randomNumber(this.minSpeed, this.maxSpeed);
            if (randomSpeed > 0) {
                const direction = particle._position.sub(this.getOrigin());
                particle.velocity = particle.velocity.add(direction.mult(randomSpeed));
            }
            return particle;
        }
        getOrigin() {
            if (!this.originCP)
                return new Vector(0, 0, 0);
            return this.originCP.getPosition();
        }
        randomVec() {
            const theta = randomNumber(0, Math.PI * 2);
            const minRadius = this.radius - this.thickness / 2;
            const maxRadius = this.radius + this.thickness / 2;
            const A = 2 / (maxRadius * maxRadius - minRadius * minRadius);
            const dist = Math.sqrt((2 * randomNumber(0, 1)) / A + minRadius * minRadius);
            let position = new Vector(dist * Math.cos(theta), dist * Math.sin(theta), 0);
            if (this.roll > 0 || this.pitch > 0 || this.yaw > 0) {
                const p = position.exportThree();
                p.applyEuler(new THREE.Euler(MathUtils.degToRad(this.roll), MathUtils.degToRad(this.pitch), MathUtils.degToRad(this.yaw)));
                position = Vector.fromThree(p);
            }
            return position.add(this.getOrigin());
        }
        nextVec() {
            const minRadius = this.radius - this.thickness / 2;
            const maxRadius = this.radius + this.thickness / 2;
            if (this.evenDistributionCount < 2) {
                return new Vector(randomNumber(minRadius, maxRadius), 0, 0).add(this.getOrigin());
            }
            const A = 2 / (maxRadius * maxRadius - minRadius * minRadius);
            const dist = Math.sqrt((2 * randomNumber(0, 1)) / A + minRadius * minRadius);
            let position = new Vector(dist * Math.cos(this.curAngle), dist * Math.sin(this.curAngle), 0);
            if (this.roll > 0 || this.pitch > 0 || this.yaw > 0) {
                const p = position.exportThree();
                p.applyEuler(new THREE.Euler(MathUtils.degToRad(this.roll), MathUtils.degToRad(this.pitch), MathUtils.degToRad(this.yaw)));
                position = Vector.fromThree(p);
            }
            this.curAngle += (Math.PI * 2) / this.evenDistributionCount;
            if (this.curAngle > Math.PI * 2)
                this.curAngle -= Math.PI * 2;
            return position.add(this.getOrigin());
        }
        randomSign(x) {
            return x * (Math.random() < 0.5 ? -1 : 1);
        }
    }
    ParticleInitializer.PositionAlongRing = PositionAlongRing;
    class PositionAlongPathRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.startCpNumber = 0;
            this.endCpNumber = 0;
            this.maxDistance = 0;
            if (options) {
                this.startCpNumber = options.startCpNumber ?? this.startCpNumber;
                this.endCpNumber = options.endCpNumber ?? this.endCpNumber;
                this.maxDistance = options.maxDistance ?? this.maxDistance;
            }
        }
        static getArgs() {
            return {
                startCpNumber: ["ControlPoint" /* FieldTypes.ControlPoint */, 0],
                endCpNumber: ["ControlPoint" /* FieldTypes.ControlPoint */, 0],
                maxDistance: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                startCpNumber: this.startCpNumber,
                endCpNumber: this.endCpNumber,
                maxDistance: this.maxDistance,
            };
        }
        onInit() {
            this.startCP = this.registerCP(this.startCpNumber);
            this.endCP = this.registerCP(this.endCpNumber);
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            particle._position = this.randomVec();
            return particle;
        }
        randomVec() {
            if (!this.startCP || !this.endCP)
                return new Vector();
            const start = this.startCP.getPosition();
            const end = this.endCP.getPosition();
            if (start.equals(end))
                return start;
            const dir = start.sub(end).normalized().negated();
            const maxDist = start.sub(end).length();
            const dist = randomNumber(0, maxDist);
            return start.add(dir.mult(dist));
        }
        randomSign(x) {
            return x * (Math.random() < 0.5 ? -1 : 1);
        }
    }
    ParticleInitializer.PositionAlongPathRandom = PositionAlongPathRandom;
    class LifetimeRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minLifetime = 0;
            this.maxLifetime = 0;
            if (options) {
                this.minLifetime = options.minLifetime ?? this.minLifetime;
                this.maxLifetime = options.maxLifetime ?? this.maxLifetime;
            }
        }
        static getArgs() {
            return {
                minLifetime: ["Number" /* FieldTypes.Number */, 0],
                maxLifetime: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                minLifetime: this.minLifetime,
                maxLifetime: this.maxLifetime,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            particle._lifeTime = randomNumber(this.minLifetime, this.maxLifetime);
            return particle;
        }
    }
    ParticleInitializer.LifetimeRandom = LifetimeRandom;
    class RadiusRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minRadius = 1;
            this.maxRadius = 1;
            if (options) {
                this.minRadius = options.minRadius ?? this.minRadius;
                this.maxRadius = options.maxRadius ?? this.maxRadius;
            }
        }
        static getArgs() {
            return {
                minRadius: ["Number" /* FieldTypes.Number */, 1],
                maxRadius: ["Number" /* FieldTypes.Number */, 1],
            };
        }
        getOptions() {
            return {
                minRadius: this.minRadius,
                maxRadius: this.maxRadius,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            particle._radius = randomNumber(this.minRadius, this.maxRadius);
            return particle;
        }
    }
    ParticleInitializer.RadiusRandom = RadiusRandom;
    class SequenceRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minSequence = 1;
            this.maxSequence = 1;
            if (options) {
                this.minSequence = options.minSequence ?? this.minSequence;
                this.maxSequence = options.maxSequence ?? this.maxSequence;
            }
        }
        static getArgs() {
            return {
                minSequence: ["Number" /* FieldTypes.Number */, 0],
                maxSequence: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                minSequence: this.minSequence,
                maxSequence: this.maxSequence,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            particle._sequence = Math.floor(randomNumber(this.minSequence, this.maxSequence));
            return particle;
        }
    }
    ParticleInitializer.SequenceRandom = SequenceRandom;
    class ColorRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.startColor = new Color();
            this.endColor = new Color();
            if (options) {
                this.startColor = options.startColor ?? this.startColor;
                this.endColor = options.endColor ?? this.endColor;
            }
        }
        static getArgs() {
            return {
                startColor: ["Color" /* FieldTypes.Color */, new Color()],
                endColor: ["Color" /* FieldTypes.Color */, new Color()],
            };
        }
        getOptions() {
            return {
                startColor: this.startColor,
                endColor: this.endColor,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            let perc = randomNumber(0, 1);
            particle._color = interpolateColors(this.startColor, this.endColor, perc);
            return particle;
        }
    }
    ParticleInitializer.ColorRandom = ColorRandom;
    class AlphaRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minAlpha = 255;
            this.maxAlpha = 255;
            if (options) {
                this.minAlpha = options.minAlpha ?? this.minAlpha;
                this.maxAlpha = options.maxAlpha ?? this.maxAlpha;
            }
        }
        static getArgs() {
            return {
                minAlpha: ["Number" /* FieldTypes.Number */, 255],
                maxAlpha: ["Number" /* FieldTypes.Number */, 255],
            };
        }
        getOptions() {
            return {
                minAlpha: this.minAlpha,
                maxAlpha: this.maxAlpha,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            particle._alpha = randomNumber(this.minAlpha, this.maxAlpha);
            return particle;
        }
    }
    ParticleInitializer.AlphaRandom = AlphaRandom;
    class RotationSpeedRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minSpeed = 0;
            this.maxSpeed = 0;
            this.baseSpeed = 0;
            this.rotationType = ParticleRotationAttributes.Roll;
            this.randomlyFlip = true;
            if (options) {
                this.minSpeed = options.minSpeed ?? this.minSpeed;
                this.maxSpeed = options.maxSpeed ?? this.maxSpeed;
                this.baseSpeed = options.baseSpeed ?? this.baseSpeed;
                this.rotationType = options.rotationType ?? this.rotationType;
                this.randomlyFlip = options.randomlyFlip ?? this.randomlyFlip;
            }
        }
        static getArgs() {
            return {
                minSpeed: ["Number" /* FieldTypes.Number */, 0],
                maxSpeed: ["Number" /* FieldTypes.Number */, 25],
                baseSpeed: ["Number" /* FieldTypes.Number */, 0],
                randomlyFlip: ["Bool" /* FieldTypes.Boolean */, true],
                rotationType: ["RotationAttribute" /* FieldTypes.RotationAttribute */, ParticleRotationAttributes.Roll],
            };
        }
        getOptions() {
            return {
                minSpeed: this.minSpeed,
                maxSpeed: this.maxSpeed,
                baseSpeed: this.baseSpeed,
                rotationType: this.rotationType,
                randomlyFlip: this.randomlyFlip,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            const particleField = `_${this.rotationType}Speed`;
            let speed = this.baseSpeed + randomNumber(this.minSpeed, this.maxSpeed);
            if (this.randomlyFlip)
                speed = randomSign(speed);
            particle[particleField] = speed;
            return particle;
        }
    }
    ParticleInitializer.RotationSpeedRandom = RotationSpeedRandom;
    class RotationRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minRot = 0;
            this.maxRot = 0;
            this.baseRot = 0;
            this.rotationType = ParticleRotationAttributes.Roll;
            this.randomlyFlip = true;
            if (options) {
                this.minRot = options.minRot ?? this.minRot;
                this.maxRot = options.maxRot ?? this.maxRot;
                this.baseRot = options.baseRot ?? this.baseRot;
                this.rotationType = options.rotationType ?? this.rotationType;
                this.randomlyFlip = options.randomlyFlip ?? this.randomlyFlip;
            }
        }
        static getArgs() {
            return {
                minRot: ["Number" /* FieldTypes.Number */, 0],
                maxRot: ["Number" /* FieldTypes.Number */, 25],
                baseRot: ["Number" /* FieldTypes.Number */, 0],
                randomlyFlip: ["Bool" /* FieldTypes.Boolean */, true],
                rotationType: ["RotationAttribute" /* FieldTypes.RotationAttribute */, ParticleRotationAttributes.Roll],
            };
        }
        getOptions() {
            return {
                minRot: this.minRot,
                maxRot: this.maxRot,
                baseRot: this.baseRot,
                rotationType: this.rotationType,
                randomlyFlip: this.randomlyFlip,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            const particleField = `_${this.rotationType}`;
            let speed = this.baseRot + randomNumber(this.minRot, this.maxRot);
            if (this.randomlyFlip)
                speed = randomSign(speed);
            particle[particleField] = speed;
            return particle;
        }
    }
    ParticleInitializer.RotationRandom = RotationRandom;
    class PositionModifyOffsetRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minOffset = new Vector();
            this.maxOffset = new Vector();
            if (options) {
                this.minOffset = options.minOffset ?? this.minOffset;
                this.maxOffset = options.maxOffset ?? this.maxOffset;
            }
        }
        static getArgs() {
            return {
                minOffset: ["Vector" /* FieldTypes.Vector */, "0 0 0"],
                maxOffset: ["Vector" /* FieldTypes.Vector */, "0 0 0"],
            };
        }
        getOptions() {
            return {
                minOffset: this.minOffset,
                maxOffset: this.maxOffset,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            let perc = randomNumber(0, 1);
            let randomVec = interpolateVectors(this.minOffset, this.maxOffset, perc);
            particle._position.addSelf(randomVec);
            return particle;
        }
    }
    ParticleInitializer.PositionModifyOffsetRandom = PositionModifyOffsetRandom;
    class VelocityRandom extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.minCoord = new Vector();
            this.maxCoord = new Vector();
            this.minSpeed = 0;
            this.maxSpeed = 0;
            if (options) {
                this.minCoord = options.minCoord ?? this.minCoord;
                this.maxCoord = options.maxCoord ?? this.maxCoord;
                this.minSpeed = options.minSpeed ?? this.minSpeed;
                this.maxSpeed = options.maxSpeed ?? this.maxSpeed;
            }
        }
        static getArgs() {
            return {
                minSpeed: ["Number" /* FieldTypes.Number */, 0],
                maxSpeed: ["Number" /* FieldTypes.Number */, 0],
                minCoord: ["Vector" /* FieldTypes.Vector */, "0 0 0"],
                maxCoord: ["Vector" /* FieldTypes.Vector */, "0 0 0"],
            };
        }
        getOptions() {
            return {
                minCoord: this.minCoord,
                maxCoord: this.maxCoord,
                minSpeed: this.minSpeed,
                maxSpeed: this.maxSpeed,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            let randomCoord = new Vector(randomNumber(this.minCoord.x, this.maxCoord.x), randomNumber(this.minCoord.y, this.maxCoord.y), randomNumber(this.minCoord.z, this.maxCoord.z));
            randomCoord.normalize();
            let randomSpeed = randomNumber(this.minSpeed, this.maxSpeed);
            particle.velocity = particle.velocity.add(randomCoord.mult(randomSpeed));
            return particle;
        }
    }
    ParticleInitializer.VelocityRandom = VelocityRandom;
    class InitFloat extends BaseParticleInitializer {
        constructor(options) {
            super();
            this.field = ParticleFloatAttributes.radius;
            this.value = 0;
            if (options) {
                this.field = options.field ?? options.field === "" ? options.field : this.field;
                this.value = options.value ?? this.value;
            }
        }
        static getArgs() {
            return {
                field: ["FloatAttribute" /* FieldTypes.FloatAttribute */, ParticleFloatAttributes.radius],
                value: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                field: this.field,
                value: this.value,
            };
        }
        onStart(particle) {
            if (this.disabled)
                return particle;
            const particleField = ("_" + this.field);
            particle[particleField] = this.value;
            return particle;
        }
    }
    ParticleInitializer.InitFloat = InitFloat;
})(ParticleInitializer || (ParticleInitializer = {}));
export class BaseParticleOperator extends BaseParticleFunction {
    constructor() {
        super("Operator" /* FunctionType.Operator */);
        this.endCapState = EndCapState.ALWAYS;
    }
    isOperator() {
        return true;
    }
}
export var ParticleOperator;
(function (ParticleOperator) {
    class MovementBasic extends BaseParticleOperator {
        constructor(options) {
            super();
            this.gravity = new Vector(0, 0, 0);
            this.drag = 0;
            if (options) {
                this.gravity = options.gravity ?? this.gravity;
                this.drag = options.drag ?? this.drag;
            }
        }
        static getArgs() {
            return {
                gravity: ["Vector" /* FieldTypes.Vector */, "0 0 0"],
                drag: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return { gravity: this.gravity, drag: this.drag };
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                particle._velocity.addSelf(this.gravity.mult(time));
                if (this.drag > 0) {
                    particle._velocity.multSelf(1 - this.drag / 10);
                }
                const velocity = particle._velocity.mult(time);
                particle._position.addSelf(velocity);
            }
            return particleList;
        }
    }
    ParticleOperator.MovementBasic = MovementBasic;
    class RotationBasic extends BaseParticleOperator {
        static getArgs() {
            return {};
        }
        getOptions() {
            return {};
        }
        constructor(options) {
            super();
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                if (Math.abs(particle.rollSpeed) > 0)
                    particle._roll += particle.rollSpeed * time;
                if (Math.abs(particle.pitchSpeed) > 0)
                    particle._pitch += particle.pitchSpeed * time;
                if (Math.abs(particle.yawSpeed) > 0)
                    particle._yaw += particle.yawSpeed * time;
            }
            return particleList;
        }
    }
    ParticleOperator.RotationBasic = RotationBasic;
    class LifespanDecay extends BaseParticleOperator {
        static getArgs() {
            return {};
        }
        getOptions() {
            return {};
        }
        constructor(options) {
            super();
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                if (particle.lifeDuration >= particle.lifeTime) {
                    particle.startEndcap();
                }
            }
            return particleList;
        }
    }
    ParticleOperator.LifespanDecay = LifespanDecay;
    class AlphaFadeOut extends BaseParticleOperator {
        constructor(options) {
            super();
            this.fadeTime = 0.25;
            if (options) {
                this.fadeTime = options.fadeTime ?? this.fadeTime;
            }
        }
        static getArgs() {
            return { fadeTime: ["Number" /* FieldTypes.Number */, 0.25] };
        }
        getOptions() {
            return { fadeTime: this.fadeTime };
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                let perc = particle.propLifeTime >= 1 - this.fadeTime
                    ? (1 - particle.propLifeTime) / this.fadeTime
                    : 1;
                particle.alpha = perc * particle.alpha;
            }
            return particleList;
        }
    }
    ParticleOperator.AlphaFadeOut = AlphaFadeOut;
    class AlphaFadeIn extends BaseParticleOperator {
        constructor(options) {
            super();
            this.fadeTime = 0.25;
            if (options) {
                this.fadeTime = options.fadeTime ?? this.fadeTime;
            }
        }
        static getArgs() {
            return { fadeTime: ["Number" /* FieldTypes.Number */, 0.25] };
        }
        getOptions() {
            return { fadeTime: this.fadeTime };
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                let perc = particle.propLifeTime <= this.fadeTime
                    ? particle.propLifeTime / this.fadeTime
                    : 1;
                particle.alpha = perc * particle.alpha;
            }
            return particleList;
        }
    }
    ParticleOperator.AlphaFadeIn = AlphaFadeIn;
    class RadiusScale extends BaseParticleOperator {
        constructor(options) {
            super();
            this.startTime = 0;
            this.endTime = 1;
            this.startScale = 1;
            this.endScale = 1;
            if (options) {
                this.startTime =
                    options.startTime !== undefined ? options.startTime : this.startTime;
                this.endTime = options.endTime !== undefined ? options.endTime : this.endTime;
                this.startScale =
                    options.startScale !== undefined ? options.startScale : this.startScale;
                this.endScale = options.endScale !== undefined ? options.endScale : this.endScale;
            }
        }
        static getArgs() {
            return {
                startTime: ["Number" /* FieldTypes.Number */, 0],
                endTime: ["Number" /* FieldTypes.Number */, 1],
                startScale: ["Number" /* FieldTypes.Number */, 1],
                endScale: ["Number" /* FieldTypes.Number */, 1],
            };
        }
        getOptions() {
            return {
                startTime: this.startTime,
                endTime: this.endTime,
                startScale: this.startScale,
                endScale: this.endScale,
            };
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                let perc = 0;
                if (particle.propLifeTime > this.endTime) {
                    perc = 1;
                }
                else if (particle.propLifeTime >= this.startTime) {
                    perc =
                        (particle.propLifeTime - this.startTime) / (this.endTime - this.startTime);
                }
                let val = this.startScale + perc * (this.endScale - this.startScale);
                particle.radius = val * particle.radius;
            }
            return particleList;
        }
    }
    ParticleOperator.RadiusScale = RadiusScale;
    class ColorFade extends BaseParticleOperator {
        constructor(options) {
            super();
            this.startTime = 0;
            this.endTime = 1;
            this.bias = 0;
            this.fadeColor = new Color();
            if (options) {
                this.startTime =
                    options.startTime !== undefined ? options.startTime : this.startTime;
                this.endTime = options.endTime !== undefined ? options.endTime : this.endTime;
                this.fadeColor =
                    options.fadeColor !== undefined ? options.fadeColor : this.fadeColor;
                this.bias = options.bias !== undefined ? options.bias : this.bias;
            }
        }
        static getArgs() {
            return {
                startTime: ["Number" /* FieldTypes.Number */, 0],
                endTime: ["Number" /* FieldTypes.Number */, 1],
                fadeColor: ["Color" /* FieldTypes.Color */, new Color()],
                bias: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                startTime: this.startTime,
                endTime: this.endTime,
                fadeColor: this.fadeColor,
                bias: this.bias,
            };
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (let index = 0; index < particleList.length; index++) {
                const particle = particleList[index];
                if (!compareEndcap(particle.endCap, this.endCapState)) {
                    continue;
                }
                let perc = 0;
                if (particle.propLifeTime > this.endTime) {
                    perc = 1;
                }
                else if (particle.propLifeTime >= this.startTime) {
                    perc =
                        (particle.propLifeTime - this.startTime) / (this.endTime - this.startTime);
                }
                let r = Math.round(interpolate(particle.color.r, this.fadeColor.r, perc, {
                    type: "EaseOutQuart" /* InterpolationType.EaseOutQuart */,
                    strength: this.bias,
                }));
                let g = Math.round(interpolate(particle.color.g, this.fadeColor.g, perc, {
                    type: "EaseOutQuart" /* InterpolationType.EaseOutQuart */,
                    strength: this.bias,
                }));
                let b = Math.round(interpolate(particle.color.b, this.fadeColor.b, perc, {
                    type: "EaseOutQuart" /* InterpolationType.EaseOutQuart */,
                    strength: this.bias,
                }));
                particle.color = new Color(r, g, b);
            }
            return particleList;
        }
    }
    ParticleOperator.ColorFade = ColorFade;
    class MovementLockToControlPoint extends BaseParticleOperator {
        constructor(options) {
            super();
            this.cpNumber = 0;
            this.callbackID = 0;
            this.curChange = new Vector();
            if (options) {
                this.cpNumber = options.cpNumber ?? this.cpNumber;
            }
        }
        static getArgs() {
            return { cpNumber: ["ControlPoint" /* FieldTypes.ControlPoint */, 0] };
        }
        getOptions() {
            return { cpNumber: this.cpNumber };
        }
        onInit() {
            this.originCP = this.registerCP(this.cpNumber);
            if (!this.originCP)
                return;
            const onChange = (newPos, diff) => {
                this.curChange.addSelf(diff);
            };
            this.callbackID = this.originCP.registerChangeCallback(onChange);
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            for (const particle of particleList) {
                particle.position.addSelf(this.curChange);
            }
            this.curChange.reset();
            return particleList;
        }
        onDestroy() {
            this.originCP?.unregisterChangeCallback(this.callbackID);
        }
    }
    ParticleOperator.MovementLockToControlPoint = MovementLockToControlPoint;
})(ParticleOperator || (ParticleOperator = {}));
export class BaseParticleEmitter extends BaseParticleFunction {
    constructor() {
        super("Emitter" /* FunctionType.Emitter */);
        this.endCapState = EndCapState.ALWAYS;
    }
    isEmitter() {
        return true;
    }
}
export var ParticleEmitter;
(function (ParticleEmitter) {
    class EmitInstantaniously extends BaseParticleEmitter {
        constructor(options) {
            super();
            this.count = 100;
            if (options) {
                this.count = options.count !== undefined ? options.count : this.count;
            }
        }
        static getArgs() {
            return { count: ["Number" /* FieldTypes.Number */, 100] };
        }
        getOptions() {
            return { count: this.count };
        }
        onStart() {
            if (this.disabled)
                return 0;
            return this.count;
        }
        onTick(time) {
            return 0;
        }
    }
    ParticleEmitter.EmitInstantaniously = EmitInstantaniously;
    class EmitContinously extends BaseParticleEmitter {
        constructor(options) {
            super();
            this.speed = 100;
            this.startTime = 0;
            this.initCount = 0;
            this.deltaTime = 0;
            if (options) {
                this.speed = options.speed !== undefined ? options.speed : this.speed;
                this.initCount =
                    options.initCount !== undefined ? options.initCount : this.initCount;
                this.deltaTime =
                    options.startTime !== undefined ? -options.startTime : this.startTime;
            }
        }
        static getArgs() {
            return {
                speed: ["Number" /* FieldTypes.Number */, 100],
                initCount: ["Number" /* FieldTypes.Number */, 0],
                startTime: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                speed: this.speed,
                startTime: this.startTime,
                initCount: this.initCount,
            };
        }
        onStart() {
            if (this.disabled)
                return 0;
            return this.initCount;
        }
        onTick(time) {
            if (this.disabled)
                return 0;
            const interval = 6 / this.speed;
            this.deltaTime += time;
            const count = Math.floor(this.deltaTime / interval);
            if (count < 1)
                return 0;
            this.deltaTime -= count * interval;
            return count;
        }
    }
    ParticleEmitter.EmitContinously = EmitContinously;
    class EmitToMaintainCount extends BaseParticleEmitter {
        constructor(options) {
            super();
            this.speed = -1;
            this.startTime = 0;
            this.maintainCount = 0;
            this.deltaTime = 0;
            if (options) {
                this.speed = options.speed !== undefined ? options.speed : this.speed;
                this.maintainCount =
                    options.maintainCount !== undefined
                        ? options.maintainCount
                        : this.maintainCount;
                this.deltaTime =
                    options.startTime !== undefined ? -options.startTime : this.startTime;
            }
        }
        static getArgs() {
            return {
                speed: ["Number" /* FieldTypes.Number */, -1],
                maintainCount: ["Number" /* FieldTypes.Number */, 0],
                startTime: ["Number" /* FieldTypes.Number */, 0],
            };
        }
        getOptions() {
            return {
                speed: this.speed,
                startTime: this.startTime,
                maintainCount: this.maintainCount,
            };
        }
        onStart() {
            if (this.disabled)
                return 0;
            return this.speed < 0 ? this.maintainCount : 0;
        }
        onTick(time, curCount = 0) {
            if (this.disabled)
                return 0;
            const maxCount = Math.max(this.maintainCount - curCount, 0);
            if (this.speed < 0)
                return maxCount;
            const interval = 6 / this.speed;
            this.deltaTime += time;
            const count = Math.floor(this.deltaTime / interval);
            if (count < 1)
                return 0;
            this.deltaTime -= count * interval;
            return Math.min(count, maxCount);
        }
    }
    ParticleEmitter.EmitToMaintainCount = EmitToMaintainCount;
})(ParticleEmitter || (ParticleEmitter = {}));
export class BaseParticleForce extends BaseParticleFunction {
    constructor() {
        super("Force" /* FunctionType.Force */);
        this.endCapState = EndCapState.ALWAYS;
    }
    isForce() {
        return true;
    }
}
export var ParticleForces;
(function (ParticleForces) {
    class PullTowardsControlPoint extends BaseParticleForce {
        constructor(options) {
            super();
            this.cpNumber = 0;
            this.pullForce = 0;
            this.callbackID = 0;
            if (options) {
                this.cpNumber = options.cpNumber ?? this.cpNumber;
                this.pullForce = options.pullForce ?? this.pullForce;
            }
        }
        static getArgs() {
            return { cpNumber: ["ControlPoint" /* FieldTypes.ControlPoint */, 0], pullForce: ["Number" /* FieldTypes.Number */, 0] };
        }
        getOptions() {
            return { cpNumber: this.cpNumber, pullForce: this.pullForce };
        }
        onInit() {
            this.originCP = this.registerCP(this.cpNumber);
            if (!this.originCP)
                return;
        }
        onTick(time, particleList) {
            if (this.disabled)
                return particleList;
            if (!this.originCP)
                return particleList;
            for (const particle of particleList) {
                const cpPos = this.originCP.getPosition();
                const direction = particle.position.directionTo(cpPos);
                // const distance = particle.position.distanceTo(cpPos);
                const force = direction.mult(this.pullForce * -time);
                particle._velocity.addSelf(force);
            }
            return particleList;
        }
        onDestroy() {
            this.originCP?.unregisterChangeCallback(this.callbackID);
        }
    }
    ParticleForces.PullTowardsControlPoint = PullTowardsControlPoint;
})(ParticleForces || (ParticleForces = {}));
