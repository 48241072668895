import * as THREE from "three";
import { getTextureFromName } from "./util";
export class SpriteLoader {
    constructor() {
        this.load();
    }
    static Create() {
        if (!this.loader)
            this.loader = new SpriteLoader();
        return this.loader;
    }
    load() {
        return new Promise((resolve) => {
            // const requestURL =
            // "https://github.nibuja-dev.xyz/res/particles/sprite_names.json";
            const requestURL = "./res/particles/sprite_names.json";
            const request = new XMLHttpRequest();
            request.open("GET", requestURL);
            request.responseType = "json";
            request.send();
            request.onload = () => {
                this.data = request.response.sprites;
                resolve();
            };
        });
    }
    async get(name) {
        if (!this.data)
            await this.load();
        return this.data.find((sprite) => sprite.name == name);
    }
    async getByPath(path) {
        if (!this.data)
            await this.load();
        return this.data.find((sprite) => sprite.path == path);
    }
    async getAll() {
        if (!this.data)
            await this.load();
        return this.data;
    }
}
export class SpriteTexture {
    constructor(path) {
        this.path = path;
        this.type = 0 /* SpriteSheetType.None */;
        this.sequences = [];
        this.size = [];
        this.maxSize = new Map();
        this.cachedTextures = [];
        const loader = SpriteLoader.Create();
        if (path === "DEFAULT_SPRITE")
            path = "res\\particles\\basic_glow.png";
        loader.getByPath(path).then((data) => {
            if (!data)
                return;
            this.type = SpriteTexture.getTypeFromData(data);
            if (this.type == 0 /* SpriteSheetType.None */) {
                this.sequences[0] = [data.path];
            }
            else if (this.type == 1 /* SpriteSheetType.Basic */) {
                for (const [index, path] of Object.entries(data.sequences)) {
                    this.sequences[parseInt(index)] = [path];
                }
            }
            else if (this.type == 2 /* SpriteSheetType.Animated */) {
                for (const [sIndex, frames] of Object.entries(data.sequences)) {
                    const seqIndex = parseInt(sIndex);
                    this.sequences[seqIndex] = [];
                    this.size[seqIndex] = [];
                    for (const [fIndex, frame] of Object.entries(frames)) {
                        this.sequences[seqIndex][parseInt(fIndex)] = frame.path;
                        const curSize = this.maxSize.get(seqIndex) ?? [0, 0];
                        this.maxSize.set(seqIndex, [
                            Math.max(curSize[0], frame.size[0]),
                            Math.max(curSize[1], frame.size[1]),
                        ]);
                        this.size[seqIndex][parseInt(fIndex)] = frame.size;
                    }
                }
            }
        });
    }
    static async isSheet(path) {
        const loader = SpriteLoader.Create();
        const spriteData = await loader.getByPath(path);
        if (!spriteData)
            return 0 /* SpriteSheetType.None */;
        return this.getTypeFromData(spriteData);
    }
    static isSheetFromData(data) {
        return this.getTypeFromData(data);
    }
    static getTypeFromData(data) {
        if (data.sequences) {
            const firstSequence = data.sequences[0];
            if (typeof firstSequence == "string")
                return 1 /* SpriteSheetType.Basic */;
            return 2 /* SpriteSheetType.Animated */;
        }
        return 0 /* SpriteSheetType.None */;
    }
    get(sequence = 0) {
        if (this.type == 0 /* SpriteSheetType.None */)
            return this.sequences[0] ? this.sequences[0][0] : undefined;
        const seq = this.sequences[Math.min(this.sequences.length - 1, sequence)];
        return seq ? seq[0] : undefined;
    }
    getFrame(sequence, frame) {
        if (this.type == 0 /* SpriteSheetType.None */)
            return this.sequences[0] ? this.sequences[0][0] : undefined;
        const seq = this.sequences[Math.min(this.sequences.length - 1, sequence)];
        return seq[Math.min(seq.length - 1, frame)];
    }
    getFrameCount(sequence) {
        if (this.type != 2 /* SpriteSheetType.Animated */)
            return 1;
        return this.sequences[sequence].length;
    }
    getTexture(sequence = 0) {
        const seq = this.cachedTextures[Math.min(this.sequences.length - 1, sequence)];
        if (seq) {
            const chachedTexture = seq[0];
            if (chachedTexture)
                return chachedTexture;
        }
        const texturePath = this.get(sequence);
        if (!texturePath)
            return;
        const texture = getTextureFromName(texturePath);
        if (!seq)
            this.cachedTextures[Math.min(this.sequences.length - 1, sequence)] = [];
        this.cachedTextures[Math.min(this.sequences.length - 1, sequence)][0] = texture;
        return texture;
    }
    getFrameTexture(sequence, frame) {
        const seq = this.cachedTextures[Math.min(this.sequences.length - 1, sequence)];
        if (seq) {
            const chachedTexture = seq[frame];
            if (chachedTexture)
                return chachedTexture;
        }
        const texturePath = this.getFrame(sequence, frame);
        if (!texturePath)
            return;
        const texture = getTextureFromName(texturePath);
        if (!seq)
            this.cachedTextures[Math.min(this.sequences.length - 1, sequence)] = [];
        this.cachedTextures[Math.min(this.sequences.length - 1, sequence)][frame] = texture;
        return texture;
    }
    precacheFrameTextures(sequence) {
        const seq = this.sequences[Math.min(this.sequences.length - 1, sequence)];
        if (!seq)
            return;
        for (let index = 0; index < seq.length; index++) {
            const image = new THREE.TextureLoader().load(seq[index]);
            if (!this.cachedTextures[Math.min(this.sequences.length - 1, sequence)])
                this.cachedTextures[Math.min(this.sequences.length - 1, sequence)] = [];
            this.cachedTextures[Math.min(this.sequences.length - 1, sequence)][index] = image;
        }
    }
    getSize(sequence, frame) {
        if (!this.size[sequence])
            return [0, 0];
        return this.size[sequence][frame] ?? [0, 0];
    }
    getMaxSize(sequence) {
        return this.maxSize.get(sequence) ?? [0, 0];
    }
}
